import { Paragraph } from '@components';
import { ReactElement, ReactNode, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  CopyRequestorButton,
  CopyRequestorContainer,
  ExpandArrow,
  InfoMoreDetails,
  MoreDetailsModified,
  RecentActivityItemContainer,
  RequestorContainer,
} from './RecentActivityItem.styles';
import { TextButton, Tooltip } from '@hexa-ui/components';

export interface RecentActivityItemProps {
  date: string | Date;
  requestor: {
    userName: string;
    userId: string;
  };
  log: {
    title: string;
    details: ReactNode[];
  };
  moreDetailsModified?: string;
  isDetailsExpandable: boolean;
  onCopySuccess?: () => void;
  onCopyError?: () => void;
}

export const RecentActivityItem = ({
  date,
  requestor,
  log,
  moreDetailsModified,
  isDetailsExpandable,
  onCopySuccess,
  onCopyError,
}: Readonly<RecentActivityItemProps>): ReactElement => {
  const { formatDate } = useIntl();
  const { formatMessage } = useIntl();

  const formattedDate = formatDate(date, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });

  const defaultExpandableDetails = 3;
  const defaultNonExpandableDetails = 1;

  const texts = {
    requestorLabel: formatMessage({ id: 'RecentActivity.REQUESTOR' }),
    requestorValue: `${requestor.userName} on ${formattedDate}`,
    showDetails: formatMessage({ id: 'RecentActivity.SHOW_DETAILS' }),
    hideDetails: formatMessage({ id: 'RecentActivity.HIDE_DETAILS' }),
  };

  const [isExpanded, setIsExpanded] = useState(false);
  const [visibleDetailsCount, setVisibleDetailsCount] = useState(defaultExpandableDetails);
  const [expandTitle, setExpandTitle] = useState(texts.showDetails);

  useEffect(() => {
    if (isExpanded) {
      setVisibleDetailsCount(log.details.length);
      setExpandTitle(texts.hideDetails);
    } else {
      setVisibleDetailsCount(defaultExpandableDetails);
      setExpandTitle(texts.showDetails);
    }
  }, [isExpanded, log.details.length]);

  const renderExpandableDetails = () => (
    <>
      {log.details.slice(0, visibleDetailsCount).map((detail, index) => (
        <Paragraph key={index} text={detail as string} />
      ))}
      {log.details.length > defaultExpandableDetails && (
        <TextButton
          iconPosition="trailing"
          icon={() => <ExpandArrow size="medium" expanded={isExpanded} />}
          css={{ color: 'blue' }}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {expandTitle}
        </TextButton>
      )}
    </>
  );

  const renderNonExpandableDetails = () => (
    <>
      <Paragraph text={log.details[0] as string} />
      {log.details.length > defaultNonExpandableDetails && (
        <MoreDetailsModified>
          <InfoMoreDetails />
          <Paragraph text={moreDetailsModified} colorType="disabled" weight="semibold" />
        </MoreDetailsModified>
      )}
    </>
  );

  return (
    <RecentActivityItemContainer>
      <Paragraph text={log.title} weight="semibold" />
      {isDetailsExpandable ? renderExpandableDetails() : renderNonExpandableDetails()}
      <RequestorContainer>
        <Paragraph
          text={
            (
              <>
                <b>{texts.requestorLabel}</b> {texts.requestorValue}
              </>
            ) as unknown as string
          }
          colorType="disabled"
        />
        <CopyRequestorContainer>
          <Tooltip placement="top" text={formatMessage({ id: 'RecentActivity.COPY_USER_ID' })}>
            <CopyRequestorButton
              textToCopy={requestor.userId}
              onSuccess={onCopySuccess}
              onError={onCopyError}
            />
          </Tooltip>
        </CopyRequestorContainer>
      </RequestorContainer>
    </RecentActivityItemContainer>
  );
};
