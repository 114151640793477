import { AuditLog, AuditLogGetParams, useGetAuditLogs } from 'admin-portal-shared-services';
import { useEffect, useState } from 'react';
import { APP_ID, DAYS_AGO, DEFAULT_PAGE_SIZE, SORT_ORDER_DESCENDENT } from './constants';
import { RetrieveAuditLogProps } from './useAuditLog.types';

const getISODateFromDaysAgo = (days: number) => {
  const date = new Date();
  date.setDate(date.getDate() - days);
  return date.toISOString();
};

export const useRetrieveAuditLog = ({ entity, entityId, country }: RetrieveAuditLogProps) => {
  const [data, setData] = useState<AuditLog[]>([]);
  const [page, setPage] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [finalDate, setFinalDate] = useState<string>(new Date().toISOString());
  const [initialDate] = useState(getISODateFromDaysAgo(DAYS_AGO));

  const shouldFetch = !!entity && !!entityId && !!country;

  const {
    data: pageData,
    isLoading,
    hasError,
  } = useGetAuditLogs(
    {
      initialDate,
      finalDate,
      country,
      page,
      entity,
      entityId,
      appId: APP_ID,
      pageSize: DEFAULT_PAGE_SIZE,
      sort: SORT_ORDER_DESCENDENT,
    } as AuditLogGetParams,
    shouldFetch
  );

  const fetchNextPage = () => {
    if (!hasError) {
      if (hasNextPage) {
        setPage(page + 1);
      }
    } else {
      setFinalDate(new Date().toISOString());
    }
  };

  useEffect(() => {
    if (pageData) {
      setData((prevData) => [...prevData, ...pageData.content]);
      setHasNextPage(!!pageData.pagination.next);
    }
  }, [pageData]);

  return {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
    hasError,
  };
};
