import { Status } from '@components/EditionStatusField/EditableStatusField.types';
import { yupResolver } from '@hookform/resolvers/yup';
import { usePatchItem } from '@hooks/query/usePatchItem/usePatchItem';
import {
  getPageName,
  getPreviousPageName,
  useRouterContext,
} from 'providers/RouterProvider/RouterProvider';
import { useEffect, useMemo, useState } from 'react';
import { Resolver, useForm, UseFormReturn } from 'react-hook-form';
import { IntlShape, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { RouterParams } from 'Router';
import { object, string } from 'yup';
import useTrackingService from '../../useTrackingService/useTrackingService';

interface ItemInformationForm {
  sku: string;
  productVariant?: string;
  seller: string;
  sellerId: string;
  sellerItemId: string;
  status: Status;
}

export interface UseItemInformationFormReturn {
  form: UseFormReturn<ItemInformationForm>;
  onSubmit: (callbackOnSuccess?: () => void) => Promise<void>;
  onEdit: () => void;
  onCancel: () => void;
  isEditing: boolean;
  isUpdating: boolean;
}

interface UseItemInformationFormParams {
  data?: ItemDetailsResponse;
}

const getValidationSchema = (formatMessage: IntlShape['formatMessage']) => {
  const requiredMessage = formatMessage({ id: 'ItemDetailsPage.FORM_REQUIRED_FIELD' });
  return object().shape({
    sku: string().trim().required(requiredMessage),
    productVariant: string().trim().optional(),
    seller: string().trim().required(requiredMessage),
    sellerId: string().trim().required(requiredMessage),
    sellerItemId: string().trim().required(requiredMessage),
    status: string().trim().required(requiredMessage),
  });
};

export const useItemInformationForm = ({
  data,
}: UseItemInformationFormParams): UseItemInformationFormReturn => {
  const { formatMessage } = useIntl();
  const { country, itemId } = useParams<RouterParams>();
  const [isEditing, setIsEditing] = useState(false);

  const { updateItem, isLoading: isUpdating } = usePatchItem({
    country,
    id: itemId,
    successMessage: formatMessage({ id: 'ItemDetailsPage.SUCCESS_UPDATE_ITEM_INFORMATION' }),
    failMessage: formatMessage({ id: 'ItemDetailsPage.ERROR_UPDATE_ITEM_INFORMATION' }),
  });

  const analytics = useTrackingService().getAnalytics();
  const routerContext = useRouterContext();

  const startEditingEvent = () => {
    const { status, sku, seller, sellerId } = formMethods.getValues();
    analytics.itemInformationEditionStarted({
      button_name: 'Edit',
      country,
      item_status: status,
      page_name: getPageName(routerContext.to),
      sku: sku as unknown as number,
      previous_page_name: getPreviousPageName(routerContext.from),
      product_name: data.product?.name,
      seller_id: sellerId as unknown as number,
      seller_name: seller,
      variant_gtin: data.variant?.gtin as unknown as number,
    });
  };

  const itemInformationEditionDiscardedEvent = () => {
    analytics.itemInformationEditionDiscarded({
      button_name: 'Cancel',
      country,
      page_name: getPageName(routerContext.to),
      previous_page_name: getPreviousPageName(routerContext.from),
    });
  };

  const itemInformationEditionSubmittedEvent = () => {
    const { status, sku, seller, sellerId } = formMethods.getValues();
    analytics.itemInformationEditionSubmitted({
      button_name: 'Update',
      country,
      item_status: status,
      page_name: getPageName(routerContext.to),
      sku: sku as unknown as number,
      previous_page_name: getPreviousPageName(routerContext.from),
      product_name: data.product?.name,
      seller_id: sellerId as unknown as number,
      seller_name: seller,
      variant_gtin: data.variant?.gtin as unknown as number,
    });
  };

  const initialValues = useMemo(
    () => ({
      sku: data?.sku,
      productVariant:
        data?.product?.name && data?.variant?.name
          ? `${data?.product?.name} / ${data?.variant?.name}`
          : undefined,
      seller: data?.vendorName,
      sellerId: data?.vendorId,
      sellerItemId: data?.vendorItemId,
      status: Status[data?.status ?? Status.DISABLED],
    }),
    [data]
  );

  const formMethods = useForm<ItemInformationForm>({
    defaultValues: initialValues,
    resolver: yupResolver(getValidationSchema(formatMessage)) as Resolver<ItemInformationForm>,
    mode: 'onChange',
  });

  const onSubmit = async (callbackOnSuccess?: () => void) => {
    const { sku, status } = formMethods.getValues();
    itemInformationEditionSubmittedEvent();

    const itemToUpdate = {
      sku: sku.trim(),
      enabled: status === Status.ENABLED,
    };

    try {
      await updateItem(itemToUpdate);
      setIsEditing(false);
      if (typeof callbackOnSuccess === 'function') callbackOnSuccess();
    } catch {}
  };

  const onEdit = () => {
    formMethods.trigger();
    setIsEditing(true);
    startEditingEvent();
  };

  const onCancel = () => {
    formMethods.reset(initialValues);
    setIsEditing(false);
    itemInformationEditionDiscardedEvent();
  };

  useEffect(() => {
    if (data) formMethods.reset(initialValues);
  }, [data, formMethods, initialValues]);

  return {
    form: formMethods,
    onSubmit,
    onEdit,
    onCancel,
    isEditing,
    isUpdating,
  };
};
