// This client was automatically generated by Segment Typewriter. ** Do Not Edit **
// To update this file, run:
//   npx typewriter

/**
 * Fired when the user clicks on the AI TOOLS FOR DESCRIPTION button at the Product Creation
 * first step
 */
export interface AIProductDescriptionCreated {
  /**
   * The text of the product description generated by the AI tools
   */
  ai_product_description: null | string;
  /**
   * The name of the button clicked
   */
  button_name: null | string;
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE
   */
  page_name: null | string;
  /**
   * The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE
   */
  previous_page_name: null | string;
  /**
   * The name of the variant to be created
   */
  product_name: null | string;
  [property: string]: any;
}

/**
 * Fired when the user clicks on CANCEL button anytime during the Assign Products steps
 */
export interface AssignProductsDiscarded {
  /**
   * The name of the button clicked
   */
  button_name: null | string;
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE
   */
  page_name: null | string;
  /**
   * The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE
   */
  previous_page_name: null | string;
  [property: string]: any;
}

/**
 * Fired when the user clicks on YES, REMOVE button at the Remove this product from category
 * modal
 */
export interface AssignProductsRemoved {
  /**
   * Present the result of the success or error unassigning produtcs, in case the error brings
   * the return message
   */
  assign_products_results: null | string;
  /**
   * The name of the button clicked
   */
  button_name: null | string;
  /**
   * The complete tier path of the category being created. Null if it is a Featured Collection
   * category
   */
  category_path: null | string;
  /**
   * The type of the category being created
   */
  category_type: null | string;
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The name of the variant to be created
   */
  product_name: null | string;
  /**
   * The name of the store
   */
  store_name: null | string;
  [property: string]: any;
}

/**
 * Fired when the user clicks on ASSIGN PRODUCTS button on the Product listing page OR the "
 * + " icon on the Product Details page
 */
export interface AssignProductsStarted {
  /**
   * The name of the button clicked
   */
  button_name: null | string;
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE
   */
  page_name: null | string;
  /**
   * The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE
   */
  previous_page_name: null | string;
  [property: string]: any;
}

/**
 * Fired when the user clicks on CONFIRM button at the last step to Assign Products
 */
export interface AssignProductsSubmitted {
  /**
   * Present the result of the success or error unassigning produtcs, in case the error brings
   * the return message
   */
  assign_products_results: null | string;
  /**
   * The name of the button clicked
   */
  button_name: null | string;
  /**
   * The complete tier path of the category being created. Null if it is a Featured Collection
   * category
   */
  category_path: null | string;
  /**
   * The type of the category being created
   */
  category_type: null | string;
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The name of the variant to be created
   */
  product_name: null | string;
  /**
   * The name of the store
   */
  store_name: null | string;
  [property: string]: any;
}

/**
 * Fired when the user clicks on YES, CANCEL button at the Cancel Import confirmatiom modal
 */
export interface BulkImportDiscarded {
  /**
   * The name of the button clicked
   */
  button_name: null | string;
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE
   */
  page_name: null | string;
  /**
   * The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE
   */
  previous_page_name: null | string;
  [property: string]: any;
}

/**
 * Fired when the user clicks on IMPORT FILE button on the Bulk Importer page
 */
export interface BulkImportStarted {
  /**
   * The name of the button clicked
   */
  button_name: null | string;
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE
   */
  page_name: null | string;
  /**
   * The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE
   */
  previous_page_name: null | string;
  [property: string]: any;
}

/**
 * Fired when the user clicks on YES, IMPORT button at the last step to import the CSV file
 */
export interface BulkImportSubmitted {
  /**
   * Present the result of the success or error when importing the CSV, in case the error
   * brings the return message
   */
  bulk_importer_results: null | string;
  /**
   * The name of the button clicked
   */
  button_name: null | string;
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The name of the selected entity. Permitted Values: ITEMS, PRODUCT
   */
  entity_name: EntityName | null;
  /**
   * The bulk importer operation type. Permitted Values: CREATION, EDITION
   */
  operation_type: OperationType | null;
  /**
   * The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE
   */
  page_name: null | string;
  /**
   * The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE
   */
  previous_page_name: null | string;
  /**
   * The name of the selected Product Type for the Product bulk import process. Null if the
   * Entity is Items
   */
  product_type_selected: null | string;
  /**
   * The name of the selected Sellers for the Items bulk import process. Null if the Entity is
   * Product
   */
  sellers_selected: null | string;
  [property: string]: any;
}

export enum EntityName {
  Items = 'ITEMS',
  Product = 'PRODUCT',
}

export enum OperationType {
  Creation = 'CREATION',
  Edition = 'EDITION',
}

/**
 * Fired when the user views the Bulk Importer Page
 */
export interface BulkImporterPageViewed {
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE
   */
  page_name: null | string;
  /**
   * The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE
   */
  previous_page_name: null | string;
  [property: string]: any;
}

/**
 * Fired when the user clicks on BACK button at the Add Products Modal
 */
export interface CategoryAddProductsEditionDiscarded {
  /**
   * The name of the button clicked
   */
  button_name: null | string;
  /**
   * The name of the category being created
   */
  category_name: null | string;
  /**
   * The complete tier path of the category being created. Null if it is a Featured Collection
   * category
   */
  category_path: null | string;
  /**
   * The status can be enabled or disabled. Permitted Values: DISABLE, ENABLE
   */
  category_status: Status | null;
  /**
   * The type of the category being created
   */
  category_type: null | string;
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The ID of the store
   */
  store_id: null | string;
  /**
   * The name of the store
   */
  store_name: null | string;
  [property: string]: any;
}

export enum Status {
  Disable = 'DISABLE',
  Enable = 'ENABLE',
}

/**
 * Fired when the user clicks on ADD PRODUCTS button at the Category Details page
 */
export interface CategoryAddProductsEditionStarted {
  /**
   * The name of the button clicked
   */
  button_name: null | string;
  /**
   * The name of the category being created
   */
  category_name: null | string;
  /**
   * The complete tier path of the category being created. Null if it is a Featured Collection
   * category
   */
  category_path: null | string;
  /**
   * The status can be enabled or disabled. Permitted Values: DISABLE, ENABLE
   */
  category_status: Status | null;
  /**
   * The type of the category being created
   */
  category_type: null | string;
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The ID of the store
   */
  store_id: null | string;
  /**
   * The name of the store
   */
  store_name: null | string;
  [property: string]: any;
}

/**
 * Fired when the user clicks on ADD SELECTED PRODUCTS button at the Add Products Modal
 */
export interface CategoryAddProductsEditionSubmitted {
  /**
   * The name of the button clicked
   */
  button_name: null | string;
  /**
   * The name of the category being created
   */
  category_name: null | string;
  /**
   * The complete tier path of the category being created. Null if it is a Featured Collection
   * category
   */
  category_path: null | string;
  /**
   * The status can be enabled or disabled. Permitted Values: DISABLE, ENABLE
   */
  category_status: Status | null;
  /**
   * The type of the category being created
   */
  category_type: null | string;
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The ID of the store
   */
  store_id: null | string;
  /**
   * The name of the store
   */
  store_name: null | string;
  [property: string]: any;
}

/**
 * Fired when the user clicks on the Category Status Toggle to enable or disable a category
 * at any point of the Category Edition flow.
 */
export interface CategoryAddProductsFilterApplied {
  /**
   * The filter options selected by the user
   */
  add_products_filter_options: AddProductsFilterOptions | null;
  /**
   * The name of the button clicked
   */
  button_name: null | string;
  /**
   * The name of the category being created
   */
  category_name: null | string;
  /**
   * The complete tier path of the category being created. Null if it is a Featured Collection
   * category
   */
  category_path: null | string;
  /**
   * The status can be enabled or disabled. Permitted Values: DISABLE, ENABLE
   */
  category_status: Status | null;
  /**
   * The type of the category being created
   */
  category_type: null | string;
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The ID of the store
   */
  store_id: null | string;
  /**
   * The name of the store
   */
  store_name: null | string;
  [property: string]: any;
}

export enum AddProductsFilterOptions {
  Brand = 'BRAND',
  Manufacturer = 'MANUFACTURER',
  ProductType = 'PRODUCT TYPE',
}

/**
 * Fired when the user triggers the Product search engine by any means
 */
export interface CategoryAddProductsSearch {
  /**
   * The search text being used when the event occured, if there is any
   */
  add_products_search_text: null | string;
  /**
   * The name of the category being created
   */
  category_name: null | string;
  /**
   * The complete tier path of the category being created. Null if it is a Featured Collection
   * category
   */
  category_path: null | string;
  /**
   * The status can be enabled or disabled. Permitted Values: DISABLE, ENABLE
   */
  category_status: Status | null;
  /**
   * The type of the category being created
   */
  category_type: null | string;
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The ID of the store
   */
  store_id: null | string;
  /**
   * The name of the store
   */
  store_name: null | string;
  [property: string]: any;
}

/**
 * Fired when the user clicks on CANCEL option at the Product Table after Starts the Change
 * Product Position flow and/or GO BACK or "X" button at the Confirm Product Position
 * confirmation modal
 */
export interface CategoryChangeProductPositionDiscarded {
  /**
   * The name of the button clicked
   */
  button_name: null | string;
  /**
   * The name of the category being created
   */
  category_name: null | string;
  /**
   * The complete tier path of the category being created. Null if it is a Featured Collection
   * category
   */
  category_path: null | string;
  /**
   * The status can be enabled or disabled. Permitted Values: DISABLE, ENABLE
   */
  category_status: Status | null;
  /**
   * The type of the category being created
   */
  category_type: null | string;
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The ID of the store
   */
  store_id: null | string;
  /**
   * The name of the store
   */
  store_name: null | string;
  [property: string]: any;
}

/**
 * Fired when the user clicks on CHANGE PRODUCT POSITION option at the More options dropdown
 * list at the Category Details Page
 */
export interface CategoryChangeProductPositionStarted {
  /**
   * The name of the button clicked
   */
  button_name: null | string;
  /**
   * The name of the category being created
   */
  category_name: null | string;
  /**
   * The complete tier path of the category being created. Null if it is a Featured Collection
   * category
   */
  category_path: null | string;
  /**
   * The status can be enabled or disabled. Permitted Values: DISABLE, ENABLE
   */
  category_status: Status | null;
  /**
   * The type of the category being created
   */
  category_type: null | string;
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The ID of the store
   */
  store_id: null | string;
  /**
   * The name of the store
   */
  store_name: null | string;
  [property: string]: any;
}

/**
 * Fired when the user clicks on Confirm button at the Confirm Product Position confirmation
 * modal
 */
export interface CategoryChangeProductPositionSubmitted {
  /**
   * The name of the button clicked
   */
  button_name: null | string;
  /**
   * The name of the category being created
   */
  category_name: null | string;
  /**
   * The complete tier path of the category being created. Null if it is a Featured Collection
   * category
   */
  category_path: null | string;
  /**
   * The status can be enabled or disabled. Permitted Values: DISABLE, ENABLE
   */
  category_status: Status | null;
  /**
   * The type of the category being created
   */
  category_type: null | string;
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The ID of the store
   */
  store_id: null | string;
  /**
   * The name of the store
   */
  store_name: null | string;
  [property: string]: any;
}

/**
 * Fired when the user clicks on DISCARD button anytime during the category creation steps
 */
export interface CategoryCreationDiscarded {
  /**
   * The name of the button clicked
   */
  button_name: null | string;
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE
   */
  page_name: null | string;
  /**
   * The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE
   */
  previous_page_name: null | string;
  /**
   * The ID of the store
   */
  store_id: null | string;
  /**
   * The name of the store
   */
  store_name: null | string;
  [property: string]: any;
}

/**
 * Fired when the user clicks on ADD NEW CATEGORY button on the Category Tree page
 */
export interface CategoryCreationStarted {
  /**
   * The name of the button clicked
   */
  button_name: null | string;
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE
   */
  page_name: null | string;
  /**
   * The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE
   */
  previous_page_name: null | string;
  /**
   * The ID of the store
   */
  store_id: null | string;
  /**
   * The name of the store
   */
  store_name: null | string;
  [property: string]: any;
}

/**
 * Fired when the user clicks on CREATE button at the last step to create a category
 */
export interface CategoryCreationSubmitted {
  /**
   * The name of the button clicked
   */
  button_name: null | string;
  /**
   * Present the result of the success or error when create the category, in case the error
   * brings the return message
   */
  category_creation_results: null | string;
  /**
   * The name of the category being created
   */
  category_name: null | string;
  /**
   * The complete tier path of the category being created. Null if it is a Featured Collection
   * category
   */
  category_path: null | string;
  /**
   * The status can be enabled or disabled. Permitted Values: DISABLE, ENABLE
   */
  category_status: Status | null;
  /**
   * The type of the category being created
   */
  category_type: null | string;
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The ID of the store
   */
  store_id: null | string;
  /**
   * The name of the store
   */
  store_name: null | string;
  [property: string]: any;
}

/**
 * Fired when the user clicks on CANCEL button at the Category Details Page
 */
export interface CategoryDetailsEditionDiscarded {
  /**
   * The name of the button clicked
   */
  button_name: null | string;
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE
   */
  page_name: null | string;
  /**
   * The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE
   */
  previous_page_name: null | string;
  /**
   * The ID of the store
   */
  store_id: null | string;
  /**
   * The name of the store
   */
  store_name: null | string;
  [property: string]: any;
}

/**
 * Fired when the user clicks on Edit button (Pencil icon) on the Category Details page
 */
export interface CategoryDetailsEditionStarted {
  /**
   * The name of the button clicked
   */
  button_name: null | string;
  /**
   * The name of the category being created
   */
  category_name: null | string;
  /**
   * The complete tier path of the category being created. Null if it is a Featured Collection
   * category
   */
  category_path: null | string;
  /**
   * The status can be enabled or disabled. Permitted Values: DISABLE, ENABLE
   */
  category_status: Status | null;
  /**
   * The type of the category being created
   */
  category_type: null | string;
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE
   */
  page_name: null | string;
  /**
   * The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE
   */
  previous_page_name: null | string;
  /**
   * The ID of the store
   */
  store_id: null | string;
  /**
   * The name of the store
   */
  store_name: null | string;
  [property: string]: any;
}

/**
 * Fired when the user clicks on SAVE button at the Category Details page
 */
export interface CategoryDetailsEditionSubmitted {
  /**
   * The name of the button clicked
   */
  button_name: null | string;
  /**
   * The name of the category being created
   */
  category_name: null | string;
  /**
   * The complete tier path of the category being created. Null if it is a Featured Collection
   * category
   */
  category_path: null | string;
  /**
   * The status can be enabled or disabled. Permitted Values: DISABLE, ENABLE
   */
  category_status: Status | null;
  /**
   * The type of the category being created
   */
  category_type: null | string;
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE
   */
  page_name: null | string;
  /**
   * The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE
   */
  previous_page_name: null | string;
  /**
   * The ID of the store
   */
  store_id: null | string;
  /**
   * The name of the store
   */
  store_name: null | string;
  [property: string]: any;
}

/**
 * Fired when the user views the Category Details Page
 */
export interface CategoryDetailsPageView {
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE
   */
  page_name: null | string;
  /**
   * The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE
   */
  previous_page_name: null | string;
  /**
   * The ID of the store
   */
  store_id: null | string;
  /**
   * The name of the store
   */
  store_name: null | string;
  [property: string]: any;
}

/**
 * Fired when the user clicks on CANCEL button at the EDIT CATEGORY POSITIONS flow
 */
export interface CategoryPositionEditionDiscarded {
  /**
   * The name of the button clicked
   */
  button_name: null | string;
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE
   */
  page_name: null | string;
  /**
   * The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE
   */
  previous_page_name: null | string;
  /**
   * The ID of the store
   */
  store_id: null | string;
  /**
   * The name of the store
   */
  store_name: null | string;
  [property: string]: any;
}

/**
 * Fired when the user clicks on EDIT CATEGORY POSITIONS button at the Category Organization
 * page
 */
export interface CategoryPositionEditionStarted {
  /**
   * The name of the button clicked
   */
  button_name: null | string;
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE
   */
  page_name: null | string;
  /**
   * The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE
   */
  previous_page_name: null | string;
  /**
   * The ID of the store
   */
  store_id: null | string;
  /**
   * The name of the store
   */
  store_name: null | string;
  [property: string]: any;
}

/**
 * Fired when the user clicks on SAVE button at the EDIT CATEGORY POSITIONS flow
 */
export interface CategoryPositionEditionSubmitted {
  /**
   * The name of the button clicked
   */
  button_name: null | string;
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE
   */
  page_name: null | string;
  /**
   * The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE
   */
  previous_page_name: null | string;
  /**
   * The ID of the store
   */
  store_id: null | string;
  /**
   * The name of the store
   */
  store_name: null | string;
  [property: string]: any;
}

/**
 * Fired when the user clicks on the Category Status Toggle to enable or disable a category
 * at any point of the Category Edition flow
 */
export interface CategoryStatusToggleChanged {
  /**
   * The name of the button clicked
   */
  button_name: null | string;
  /**
   * The name of the category being created
   */
  category_name: null | string;
  /**
   * The complete tier path of the category being created. Null if it is a Featured Collection
   * category
   */
  category_path: null | string;
  /**
   * The category status before the user changes it
   */
  category_previous_status: Status | null;
  /**
   * The status can be enabled or disabled. Permitted Values: DISABLE, ENABLE
   */
  category_status: Status | null;
  /**
   * The type of the category being created
   */
  category_type: null | string;
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The ID of the store
   */
  store_id: null | string;
  /**
   * The name of the store
   */
  store_name: null | string;
  [property: string]: any;
}

/**
 * Fired when the user views the Category Tree Page
 */
export interface CategoryTreePageViewed {
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE
   */
  page_name: null | string;
  /**
   * The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE
   */
  previous_page_name: null | string;
  /**
   * The ID of the store
   */
  store_id: null | string;
  /**
   * The name of the store
   */
  store_name: null | string;
  [property: string]: any;
}

/**
 * Fired when the user clicks on CANCEL option at the Product Table after Starts the
 * Unassing Product flow and/or NO, GO BACK or "X" button at the Remove products form this
 * category confirmation modal
 */
export interface CategoryUnassignProductDiscarded {
  /**
   * The name of the button clicked
   */
  button_name: null | string;
  /**
   * The name of the category being created
   */
  category_name: null | string;
  /**
   * The complete tier path of the category being created. Null if it is a Featured Collection
   * category
   */
  category_path: null | string;
  /**
   * The status can be enabled or disabled. Permitted Values: DISABLE, ENABLE
   */
  category_status: Status | null;
  /**
   * The type of the category being created
   */
  category_type: null | string;
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The ID of the store
   */
  store_id: null | string;
  /**
   * The name of the store
   */
  store_name: null | string;
  [property: string]: any;
}

/**
 * Fired when the user clicks on UNASSIGN PRODUCTS option at the More options dropdown list
 * at the Category Details Page
 */
export interface CategoryUnassignProductStarted {
  /**
   * The name of the button clicked
   */
  button_name: null | string;
  /**
   * The name of the category being created
   */
  category_name: null | string;
  /**
   * The complete tier path of the category being created. Null if it is a Featured Collection
   * category
   */
  category_path: null | string;
  /**
   * The status can be enabled or disabled. Permitted Values: DISABLE, ENABLE
   */
  category_status: Status | null;
  /**
   * The type of the category being created
   */
  category_type: null | string;
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The ID of the store
   */
  store_id: null | string;
  /**
   * The name of the store
   */
  store_name: null | string;
  [property: string]: any;
}

/**
 * Fired when the user clicks on YES, REMOVE button at the Remove products form this
 * category confirmation modal
 */
export interface CategoryUnassignProductSubmitted {
  /**
   * The name of the button clicked
   */
  button_name: null | string;
  /**
   * The name of the category being created
   */
  category_name: null | string;
  /**
   * The complete tier path of the category being created. Null if it is a Featured Collection
   * category
   */
  category_path: null | string;
  /**
   * The status can be enabled or disabled. Permitted Values: DISABLE, ENABLE
   */
  category_status: Status | null;
  /**
   * The type of the category being created
   */
  category_type: null | string;
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The ID of the store
   */
  store_id: null | string;
  /**
   * The name of the store
   */
  store_name: null | string;
  [property: string]: any;
}

/**
 * Fired when the user views the Item Details Page
 */
export interface ItemDetailsPageViewed {
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE
   */
  page_name: null | string;
  /**
   * The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE
   */
  previous_page_name: null | string;
  [property: string]: any;
}

/**
 * Fired when the user clicks on APPLY at the Item filter modal
 */
export interface ItemFilterInteraction {
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The filter query entered by the user.
   */
  item_filter_query: null | string;
  /**
   * How many items were on the filtered list
   */
  item_filter_result_count: number | null;
  /**
   * The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE
   */
  page_name: null | string;
  /**
   * The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE
   */
  previous_page_name: null | string;
  /**
   * The Vendor ID of the item
   */
  vendor_id: null | string;
  /**
   * The Vendor name of the Item
   */
  vendor_name: null | string;
  [property: string]: any;
}

/**
 * Fired when the user clicks on CANCEL button anytime during the Item Information edition.
 */
export interface ItemInformationEditionDiscarded {
  /**
   * The name of the button clicked
   */
  button_name: null | string;
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE
   */
  page_name: null | string;
  /**
   * The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE
   */
  previous_page_name: null | string;
  [property: string]: any;
}

/**
 * Fired when the user clicks on Edit button (Pencil icon) on the Item information section
 * at the Item details page.
 */
export interface ItemInformationEditionStarted {
  /**
   * The name of the button clicked
   */
  button_name: null | string;
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The status can be enable or disable.
   */
  item_status: null | string;
  /**
   * The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE
   */
  page_name: null | string;
  /**
   * The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE
   */
  previous_page_name: null | string;
  /**
   * The name of the variant to be created
   */
  product_name: null | string;
  /**
   * Identifier that distinguishes sellers on the platform.
   */
  seller_id: number | null;
  /**
   * The seller´s name of the product to be edited.
   */
  seller_name: null | string;
  /**
   * The SKU number.
   */
  sku: number | null;
  /**
   * The GTIN is the number used to globally identify the product
   */
  variant_gtin: number | null;
  [property: string]: any;
}

/**
 * Fired when the user clicks on Save changes button on the Item Information section at the
 * Item details page and/or clicks on the Update button at the confirmation modal to update
 * the SKU number.
 */
export interface ItemInformationEditionSubmitted {
  /**
   * The name of the button clicked
   */
  button_name: null | string;
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The status can be enable or disable.
   */
  item_status: null | string;
  /**
   * The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE
   */
  page_name: null | string;
  /**
   * The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE
   */
  previous_page_name: null | string;
  /**
   * The name of the variant to be created
   */
  product_name: null | string;
  /**
   * Identifier that distinguishes sellers on the platform.
   */
  seller_id: number | null;
  /**
   * The seller´s name of the product to be edited.
   */
  seller_name: null | string;
  /**
   * The SKU number.
   */
  sku: number | null;
  /**
   * The GTIN is the number used to globally identify the product
   */
  variant_gtin: number | null;
  [property: string]: any;
}

/**
 * Fired when the user views the Item Listing Page
 */
export interface ItemListingPageViewed {
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE
   */
  page_name: null | string;
  /**
   * The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE
   */
  previous_page_name: null | string;
  [property: string]: any;
}

/**
 * Fired when the user triggers the Item search engine by any means
 */
export interface ItemSearch {
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The search query entered by the user.
   */
  item_search_query: null | string;
  /**
   * How many items were on the search resulting list
   */
  item_search_result_count: number | null;
  /**
   * The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE
   */
  page_name: null | string;
  /**
   * The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE
   */
  previous_page_name: null | string;
  /**
   * The Vendor ID of the item
   */
  vendor_id: null | string;
  /**
   * The Vendor name of the Item
   */
  vendor_name: null | string;
  [property: string]: any;
}

/**
 * Fired when the user clicks on CANCEL button anytime during the Item Type edition.
 */
export interface ItemTypeEditionDiscarded {
  /**
   * The name of the button clicked
   */
  button_name: null | string;
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE
   */
  page_name: null | string;
  /**
   * The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE
   */
  previous_page_name: null | string;
  [property: string]: any;
}

/**
 * Fired when the user clicks on Edit button (Pencil icon) on the Item type section at the
 * Item details page.
 */
export interface ItemTypeEditionStarted {
  /**
   * The name of the button clicked
   */
  button_name: null | string;
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The status can be enable or disable.
   */
  item_status: null | string;
  /**
   * The attributes and values at the start of the edition process.
   */
  item_type_attributes: null | string;
  /**
   * The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE
   */
  page_name: null | string;
  /**
   * The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE
   */
  previous_page_name: null | string;
  /**
   * The name of the variant to be created
   */
  product_name: null | string;
  /**
   * Identifier that distinguishes sellers on the platform.
   */
  seller_id: number | null;
  /**
   * The seller´s name of the product to be edited.
   */
  seller_name: null | string;
  /**
   * The SKU number.
   */
  sku: number | null;
  /**
   * The GTIN is the number used to globally identify the product
   */
  variant_gtin: number | null;
  [property: string]: any;
}

/**
 * Fired when the user clicks on CANCEL button anytime during the Item Type edition.
 */
export interface ItemTypeEditionSubmitted {
  /**
   * The name of the button clicked
   */
  button_name: null | string;
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE
   */
  page_name: null | string;
  /**
   * The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE
   */
  previous_page_name: null | string;
  [property: string]: any;
}

/**
 * Fired when the user clicks on DISCARD button anytime during the product creation steps
 */
export interface ProductCreationDiscarded {
  /**
   * The name of the button clicked
   */
  button_name: null | string;
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE
   */
  page_name: null | string;
  /**
   * The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE
   */
  previous_page_name: null | string;
  [property: string]: any;
}

/**
 * Fired when the user clicks on CREATE PRODUCT button on the Product listing page
 */
export interface ProductCreationStarted {
  /**
   * The name of the button clicked
   */
  button_name: null | string;
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE
   */
  page_name: null | string;
  /**
   * The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE
   */
  previous_page_name: null | string;
  [property: string]: any;
}

/**
 * Fired when the user clicks on CREATE button at the last step to create the product
 */
export interface ProductCreationSubmitted {
  /**
   * The name of the button clicked
   */
  button_name: null | string;
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE
   */
  page_name: null | string;
  /**
   * The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE
   */
  previous_page_name: null | string;
  /**
   * A brand is a distinct representation of a product, service or company through a symbolic
   * name, design, or any unique characteristic that identifies it in the market
   */
  product_brand: null | string;
  /**
   * It must be an optinal reference if the variant is beeing created with image
   */
  product_image: { [key: string]: any } | null;
  /**
   * A manufacturer is a company or entity that produces or creates products
   */
  product_manufacture: null | string;
  /**
   * The name of the variant to be created
   */
  product_name: null | string;
  /**
   * Present the result of the success or error when registering the product, in case the
   * error brings the return message
   */
  product_result: null | string;
  /**
   * The status can be enable or disable
   */
  product_status: Status | null;
  /**
   * The GTIN is the number used to globally identify the product
   */
  variant_gtin: number | null;
  [property: string]: any;
}

/**
 * Fired when the user views the Product Details Page
 */
export interface ProductDetailsPageViewed {
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE
   */
  page_name: null | string;
  /**
   * The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE
   */
  previous_page_name: null | string;
  [property: string]: any;
}

/**
 * Fired when the user changes the Product Image on the Product Image section at the
 * Products details page
 */
export interface ProductImageEditionSubmitted {
  /**
   * The name of the button clicked
   */
  button_name: null | string;
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE
   */
  page_name: null | string;
  /**
   * The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE
   */
  previous_page_name: null | string;
  /**
   * A brand is a distinct representation of a product, service or company through a symbolic
   * name, design, or any unique characteristic that identifies it in the market
   */
  product_brand: null | string;
  /**
   * The text of the product description generated by the AI tools
   */
  product_description: null | string;
  /**
   * It must be an optinal reference if the variant is beeing created with image
   */
  product_image: { [key: string]: any } | null;
  /**
   * A manufacturer is a company or entity that produces or creates products
   */
  product_manufacture: null | string;
  /**
   * The name of the variant to be created
   */
  product_name: null | string;
  /**
   * The status can be enable or disable
   */
  product_status: Status | null;
  [property: string]: any;
}

/**
 * Fired when the user clicks on CANCEL button anytime during the product information edition
 */
export interface ProductInformationEditionDiscarded {
  /**
   * The name of the button clicked
   */
  button_name: null | string;
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE
   */
  page_name: null | string;
  /**
   * The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE
   */
  previous_page_name: null | string;
  [property: string]: any;
}

/**
 * Fired when the user clicks on Edit button (Pencil icon) on the Product information
 * section at the Products details page
 */
export interface ProductInformationEditionStarted {
  /**
   * The name of the button clicked
   */
  button_name: null | string;
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE
   */
  page_name: null | string;
  /**
   * The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE
   */
  previous_page_name: null | string;
  /**
   * A brand is a distinct representation of a product, service or company through a symbolic
   * name, design, or any unique characteristic that identifies it in the market
   */
  product_brand: null | string;
  /**
   * The text of the product description generated by the AI tools
   */
  product_description: null | string;
  /**
   * A manufacturer is a company or entity that produces or creates products
   */
  product_manufacture: null | string;
  /**
   * The name of the variant to be created
   */
  product_name: null | string;
  /**
   * The status can be enable or disable
   */
  product_status: Status | null;
  [property: string]: any;
}

/**
 * Fired when the user clicks on Save changes button on the Product information section at
 * the Products details page
 */
export interface ProductInformationEditionSubmitted {
  /**
   * The name of the button clicked
   */
  button_name: null | string;
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE
   */
  page_name: null | string;
  /**
   * The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE
   */
  previous_page_name: null | string;
  /**
   * A brand is a distinct representation of a product, service or company through a symbolic
   * name, design, or any unique characteristic that identifies it in the market
   */
  product_brand: null | string;
  /**
   * The text of the product description generated by the AI tools
   */
  product_description: null | string;
  /**
   * A manufacturer is a company or entity that produces or creates products
   */
  product_manufacture: null | string;
  /**
   * The name of the variant to be created
   */
  product_name: null | string;
  /**
   * The status can be enable or disable
   */
  product_status: Status | null;
  [property: string]: any;
}

/**
 * Fired when the user views the Product Listing Page
 */
export interface ProductListingPageViewed {
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE
   */
  page_name: null | string;
  /**
   * The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE
   */
  previous_page_name: null | string;
  [property: string]: any;
}

/**
 * Fired when the user clicks on CANCEL button anytime during the product type values edition
 */
export interface ProductTypeValuesEditionDiscarded {
  /**
   * The name of the button clicked
   */
  button_name: null | string;
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE
   */
  page_name: null | string;
  /**
   * The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE
   */
  previous_page_name: null | string;
  [property: string]: any;
}

/**
 * Retrieving data. Wait a few seconds and try to cut or copy again.
 */
export interface ProductTypeValuesEditionStarted {
  /**
   * The name of the button clicked
   */
  button_name: null | string;
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE
   */
  page_name: null | string;
  /**
   * The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE
   */
  previous_page_name: null | string;
  /**
   * A brand is a distinct representation of a product, service or company through a symbolic
   * name, design, or any unique characteristic that identifies it in the market
   */
  product_brand: null | string;
  /**
   * The text of the product description generated by the AI tools
   */
  product_description: null | string;
  /**
   * A manufacturer is a company or entity that produces or creates products
   */
  product_manufacture: null | string;
  /**
   * The name of the variant to be created
   */
  product_name: null | string;
  /**
   * The status can be enable or disable
   */
  product_status: Status | null;
  /**
   * The attributes and values at the start of the edition process
   */
  product_type_attributes: null | string;
  [property: string]: any;
}

/**
 * Fired when the user clicks on Save changes button on the Product Type section at the
 * Products details page
 */
export interface ProductTypeValuesEditionSubmitted {
  /**
   * The name of the button clicked
   */
  button_name: null | string;
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE
   */
  page_name: null | string;
  /**
   * The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE
   */
  previous_page_name: null | string;
  /**
   * A brand is a distinct representation of a product, service or company through a symbolic
   * name, design, or any unique characteristic that identifies it in the market
   */
  product_brand: null | string;
  /**
   * The text of the product description generated by the AI tools
   */
  product_description: null | string;
  /**
   * A manufacturer is a company or entity that produces or creates products
   */
  product_manufacture: null | string;
  /**
   * The name of the variant to be created
   */
  product_name: null | string;
  /**
   * The status can be enable or disable
   */
  product_status: Status | null;
  /**
   * The attributes and values at the start of the edition process
   */
  product_type_attributes: null | string;
  [property: string]: any;
}

/**
 * Fired when the user clicks on YES, CANCEL button on the modal anytime during the variant
 * creation steps
 */
export interface VariantCreationDiscarded {
  /**
   * The name of the button clicked
   */
  button_name: null | string;
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE
   */
  page_name: null | string;
  /**
   * The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE
   */
  previous_page_name: null | string;
  [property: string]: any;
}

/**
 * Fired when the user clicks on CREATE VARIANT icon on the product details page
 */
export interface VariantCreationStarted {
  /**
   * The name of the button clicked
   */
  button_name: null | string;
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE
   */
  page_name: null | string;
  /**
   * The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE
   */
  previous_page_name: null | string;
  [property: string]: any;
}

/**
 * Fired when the user clicks on CREATE button at the last step to create the variant AND
 * when the user clicks on CREATE button at the last step to create the product
 */
export interface VariantCreationSubmitted {
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE
   */
  page_name: null | string;
  /**
   * The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE
   */
  previous_page_name: null | string;
  /**
   * The GTIN is the number used to globally identify the product
   */
  variant_gtin: number | null;
  /**
   * It must be an optinal reference if the variant is beeing created with image
   */
  variant_image: { [key: string]: any } | null;
  /**
   * The name of the variant to be created
   */
  variant_name: null | string;
  /**
   * Present the result of the success or error when registering the variant, in case the
   * error brings the return message
   */
  variant_result: null | string;
  /**
   * The status can be enable or disable
   */
  variant_status: Status | null;
  [property: string]: any;
}

/**
 * Fired when the user clicks on CANCEL button anytime during the Variant details edition
 */
export interface VariantDetailsEditionDiscarded {
  /**
   * The name of the button clicked
   */
  button_name: null | string;
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE
   */
  page_name: null | string;
  /**
   * The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE
   */
  previous_page_name: null | string;
  [property: string]: any;
}

/**
 * Fired when the user clicks on Edit button (Pencil icon) on the Product type section at
 * the Variant details page
 */
export interface VariantDetailsEditionStarted {
  /**
   * The name of the button clicked
   */
  button_name: null | string;
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE
   */
  page_name: null | string;
  /**
   * The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE
   */
  previous_page_name: null | string;
  /**
   * The attributes and values at the start of the edition process
   */
  variant_details_attributes: null | string;
  /**
   * The GTIN is the number used to globally identify the product
   */
  variant_gtin: number | null;
  /**
   * The name of the variant to be created
   */
  variant_name: null | string;
  /**
   * The status can be enable or disable
   */
  variant_status: Status | null;
  [property: string]: any;
}

/**
 * Fired when the user clicks on Save changes button on the Product type section at the
 * Variant details page
 */
export interface VariantDetailsEditionSubmitted {
  /**
   * The name of the button clicked
   */
  button_name: null | string;
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE
   */
  page_name: null | string;
  /**
   * The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE
   */
  previous_page_name: null | string;
  /**
   * The attributes and values at the start of the edition process
   */
  variant_details_attributes: null | string;
  /**
   * The GTIN is the number used to globally identify the product
   */
  variant_gtin: number | null;
  /**
   * The name of the variant to be created
   */
  variant_name: null | string;
  /**
   * The status can be enable or disable
   */
  variant_status: Status | null;
  [property: string]: any;
}

/**
 * Fired when the user views the Variant Details Page
 */
export interface VariantDetailsPageViewed {
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE
   */
  page_name: null | string;
  /**
   * The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE
   */
  previous_page_name: null | string;
  [property: string]: any;
}

/**
 * Fired when the user changes the Variant Image on the Variant Image section at the Variant
 * details page
 */
export interface VariantImageEditionSubmitted {
  /**
   * The name of the button clicked
   */
  button_name: null | string;
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE
   */
  page_name: null | string;
  /**
   * The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE
   */
  previous_page_name: null | string;
  /**
   * The GTIN is the number used to globally identify the product
   */
  variant_gtin: number | null;
  /**
   * It must be an optinal reference if the variant is beeing created with image
   */
  variant_image: { [key: string]: any } | null;
  /**
   * The name of the variant to be created
   */
  variant_name: null | string;
  /**
   * The status can be enable or disable
   */
  variant_status: Status | null;
  [property: string]: any;
}

/**
 * Fired when the user clicks on CANCEL button anytime during the Variant Information edition
 */
export interface VariantInformationEditionDiscarded {
  /**
   * The name of the button clicked
   */
  button_name: null | string;
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE
   */
  page_name: null | string;
  /**
   * The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE
   */
  previous_page_name: null | string;
  [property: string]: any;
}

/**
 * Fired when the user clicks on Edit button (Pencil icon) on the Variant Information
 * section at the Variant details page
 */
export interface VariantInformationEditionStarted {
  /**
   * The name of the button clicked
   */
  button_name: null | string;
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE
   */
  page_name: null | string;
  /**
   * The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE
   */
  previous_page_name: null | string;
  /**
   * The GTIN is the number used to globally identify the product
   */
  variant_gtin: number | null;
  /**
   * The name of the variant to be created
   */
  variant_name: null | string;
  /**
   * The status can be enable or disable
   */
  variant_status: Status | null;
  [property: string]: any;
}

/**
 * Fired when the user clicks on Save changes button on the Variant Information section at
 * the Variant details page
 */
export interface VariantInformationEditionSubmitted {
  /**
   * The name of the button clicked
   */
  button_name: null | string;
  /**
   * The afected country name
   */
  country: null | string;
  /**
   * The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE
   */
  page_name: null | string;
  /**
   * The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE,
   * VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE
   */
  previous_page_name: null | string;
  /**
   * The GTIN is the number used to globally identify the product
   */
  variant_gtin: number | null;
  /**
   * The name of the variant to be created
   */
  variant_name: null | string;
  /**
   * The status can be enable or disable
   */
  variant_status: Status | null;
  [property: string]: any;
}

/**
 * Ajv is a peer dependency for development builds. It's used to apply run-time validation
 * to message payloads before passing them on to the underlying analytics instance.
 *
 * Note that the production bundle does not depend on Ajv.
 *
 * You can install it with: `npm install --save-dev ajv`.
 */
import Ajv, { ErrorObject } from 'ajv';

/**
 * The analytics.js snippet should be available via window.analytics.
 * You can install it by following instructions at: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/
 * Make sure to also include the TypeScript declarations with: `npm install @segment/analytics-next` (install with --save-dev for type definitions only).
 *
 * If you don't want to use the snippet, you can also install the `@segment/analytics-next` library as a *production* dependency and use it like this:
 * ```ts
 * import { AnalyticsBrowser } from '@segment/analytics-next'
 * import { setTypewriterOptions } from './analytics'
 *
 * const analytics = AnalyticsBrowser.load({ writeKey: 'SEGMENT_WRITE_KEY' })
 *
 * setTypewriterOptions({ analytics: analytics })
 */
import type {
  AnalyticsSnippet,
  Analytics,
  AnalyticsBrowser,
  Options,
} from '@segment/analytics-next';

declare global {
  interface Window {
    analytics: AnalyticsSnippet;
  }
}

type AnyAnalytics = AnalyticsSnippet | Analytics | AnalyticsBrowser;

/** The callback exposed by analytics.js. */
export type Callback = () => void;

export type ViolationHandler = (message: Record<string, any>, violations: ErrorObject[]) => void;

/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * This handler will log a warning message to the console.
 */
export const defaultValidationErrorHandler: ViolationHandler = (message, violations) => {
  const msg = JSON.stringify(
    {
      type: 'Typewriter JSON Schema Validation Error',
      description:
        `You made an analytics call (${message.event}) using Typewriter that doesn't match the ` +
        'Tracking Plan spec.',
      errors: violations,
    },
    undefined,
    2
  );

  console.warn(msg);
};

let onViolation = defaultValidationErrorHandler;

let analytics: () => AnyAnalytics | undefined = () => {
  return window.analytics;
};

/** Options to customize the runtime behavior of a Typewriter client. */
export interface TypewriterOptions {
  /**
   * Underlying analytics instance where analytics calls are forwarded on to.
   * Defaults to window.analytics.
   */
  analytics?: AnyAnalytics;
  /**
   * Handler fired when if an event does not match its spec. This handler
   * does not fire in production mode, because it requires inlining the full
   * JSON Schema spec for each event in your Tracking Plan.
   *
   * By default, it will throw errors if NODE_ENV = "test" so that tests will fail
   * if a message does not match the spec. Otherwise, errors will be logged to stderr.
   */
  onViolation?: ViolationHandler;
}

/**
 * Updates the run-time configuration of this Typewriter client.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {AnyAnalytics} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setTypewriterOptions(options: TypewriterOptions) {
  analytics = options.analytics ? () => options.analytics || window.analytics : analytics;
  onViolation = options.onViolation || onViolation;
}

/**
 * Validates a message against a JSON Schema using Ajv. If the message
 * is invalid, the `onViolation` handler will be called.
 */
function validateAgainstSchema(message: Record<string, any>, schema: object) {
  const ajv = new Ajv({ allErrors: true, verbose: true });

  if (!ajv.validate(schema, message) && ajv.errors) {
    onViolation(message, ajv.errors);
  }
}

/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext(message: Options = {}): Options {
  return {
    ...message,
    context: {
      ...(message.context || {}),
      typewriter: {
        language: 'typescript',
        version: '9.1.0',
      },
    },
  };
}

/**
 * Fires a 'AIProductDescriptionCreated' track call.
 *
 * @param AIProductDescriptionCreated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function aIProductDescriptionCreated(
  props: AIProductDescriptionCreated,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'AI_Product_Description_Created',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Fired when the user clicks on the AI TOOLS FOR DESCRIPTION button at the Product Creation first step',
    properties: {
      ai_product_description: {
        description: 'The text of the product description generated by the AI tools',
        type: ['string', 'null'],
      },
      button_name: { description: 'The name of the button clicked', type: ['string', 'null'] },
      country: { description: 'The afected country name', type: ['string', 'null'] },
      page_name: {
        description:
          'The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE',
        type: ['string', 'null'],
      },
      previous_page_name: {
        description:
          'The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE',
        type: ['string', 'null'],
      },
      product_name: {
        description: 'The name of the variant to be created',
        type: ['string', 'null'],
      },
    },
    required: [
      'country',
      'button_name',
      'previous_page_name',
      'page_name',
      'ai_product_description',
      'product_name',
    ],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'AI Product Description Created',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'AssignProductsDiscarded' track call.
 *
 * @param AssignProductsDiscarded props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function assignProductsDiscarded(
  props: AssignProductsDiscarded,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Assign_Products_Discarded',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Fired when the user clicks on CANCEL button anytime during the Assign Products steps',
    properties: {
      button_name: { description: 'The name of the button clicked', type: ['string', 'null'] },
      country: { description: 'The afected country name', type: ['string', 'null'] },
      page_name: {
        description:
          'The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE',
        type: ['string', 'null'],
      },
      previous_page_name: {
        description:
          'The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE',
        type: ['string', 'null'],
      },
    },
    required: ['button_name', 'previous_page_name', 'page_name', 'country'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Assign Products Discarded',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'AssignProductsRemoved' track call.
 *
 * @param AssignProductsRemoved props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function assignProductsRemoved(
  props: AssignProductsRemoved,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Assign_Products_Removed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Fired when the user clicks on YES, REMOVE button at the Remove this product from category modal',
    properties: {
      assign_products_results: {
        description:
          'Present the result of the success or error unassigning produtcs, in case the error brings the return message',
        type: ['string', 'null'],
      },
      button_name: { description: 'The name of the button clicked', type: ['string', 'null'] },
      category_path: {
        description:
          'The complete tier path of the category being created. Null if it is a Featured Collection category',
        type: ['string', 'null'],
      },
      category_type: {
        description: 'The type of the category being created',
        type: ['string', 'null'],
      },
      country: { description: 'The afected country name', type: ['string', 'null'] },
      product_name: {
        description: 'The name of the variant to be created',
        type: ['string', 'null'],
      },
      store_name: { description: 'The name of the store', type: ['string', 'null'] },
    },
    required: [
      'button_name',
      'store_name',
      'category_type',
      'category_path',
      'product_name',
      'assign_products_results',
      'country',
    ],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Assign Products Removed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'AssignProductsStarted' track call.
 *
 * @param AssignProductsStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function assignProductsStarted(
  props: AssignProductsStarted,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Assign_Products_Started',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Fired when the user clicks on ASSIGN PRODUCTS button on the Product listing page OR the " + " icon on the Product Details page',
    properties: {
      button_name: { description: 'The name of the button clicked', type: ['string', 'null'] },
      country: { description: 'The afected country name', type: ['string', 'null'] },
      page_name: {
        description:
          'The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE',
        type: ['string', 'null'],
      },
      previous_page_name: {
        description:
          'The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE',
        type: ['string', 'null'],
      },
    },
    required: ['button_name', 'previous_page_name', 'page_name', 'country'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Assign Products Started',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'AssignProductsSubmitted' track call.
 *
 * @param AssignProductsSubmitted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function assignProductsSubmitted(
  props: AssignProductsSubmitted,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Assign_Products_Submitted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Fired when the user clicks on CONFIRM button at the last step to Assign Products',
    properties: {
      assign_products_results: {
        description:
          'Present the result of the success or error unassigning produtcs, in case the error brings the return message',
        type: ['string', 'null'],
      },
      button_name: { description: 'The name of the button clicked', type: ['string', 'null'] },
      category_path: {
        description:
          'The complete tier path of the category being created. Null if it is a Featured Collection category',
        type: ['string', 'null'],
      },
      category_type: {
        description: 'The type of the category being created',
        type: ['string', 'null'],
      },
      country: { description: 'The afected country name', type: ['string', 'null'] },
      product_name: {
        description: 'The name of the variant to be created',
        type: ['string', 'null'],
      },
      store_name: { description: 'The name of the store', type: ['string', 'null'] },
    },
    required: [
      'button_name',
      'store_name',
      'category_type',
      'category_path',
      'product_name',
      'assign_products_results',
      'country',
    ],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Assign Products Submitted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'BulkImportDiscarded' track call.
 *
 * @param BulkImportDiscarded props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bulkImportDiscarded(
  props: BulkImportDiscarded,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Bulk_Import_Discarded',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Fired when the user clicks on YES, CANCEL button at the Cancel Import confirmatiom modal',
    properties: {
      button_name: { description: 'The name of the button clicked', type: ['string', 'null'] },
      country: { description: 'The afected country name', type: ['string', 'null'] },
      page_name: {
        description:
          'The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE',
        type: ['string', 'null'],
      },
      previous_page_name: {
        description:
          'The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE',
        type: ['string', 'null'],
      },
    },
    required: ['button_name', 'previous_page_name', 'page_name', 'country'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Bulk Import Discarded',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'BulkImportStarted' track call.
 *
 * @param BulkImportStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bulkImportStarted(
  props: BulkImportStarted,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Bulk_Import_Started',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Fired when the user clicks on IMPORT FILE button on the Bulk Importer page',
    properties: {
      button_name: { description: 'The name of the button clicked', type: ['string', 'null'] },
      country: { description: 'The afected country name', type: ['string', 'null'] },
      page_name: {
        description:
          'The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE',
        type: ['string', 'null'],
      },
      previous_page_name: {
        description:
          'The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE',
        type: ['string', 'null'],
      },
    },
    required: ['button_name', 'previous_page_name', 'page_name', 'country'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Bulk Import Started',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'BulkImportSubmitted' track call.
 *
 * @param BulkImportSubmitted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bulkImportSubmitted(
  props: BulkImportSubmitted,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Bulk_Import_Submitted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Fired when the user clicks on YES, IMPORT button at the last step to import the CSV file',
    properties: {
      bulk_importer_results: {
        description:
          'Present the result of the success or error when importing the CSV, in case the error brings the return message',
        type: ['string', 'null'],
      },
      button_name: { description: 'The name of the button clicked', type: ['string', 'null'] },
      country: { description: 'The afected country name', type: ['string', 'null'] },
      entity_name: {
        description: 'The name of the selected entity. Permitted Values: ITEMS, PRODUCT',
        enum: ['ITEMS', 'PRODUCT', null],
        type: ['string', 'null'],
      },
      operation_type: {
        description: 'The bulk importer operation type. Permitted Values: CREATION, EDITION',
        enum: ['CREATION', 'EDITION', null],
        type: ['string', 'null'],
      },
      page_name: {
        description:
          'The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE',
        type: ['string', 'null'],
      },
      previous_page_name: {
        description:
          'The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE',
        type: ['string', 'null'],
      },
      product_type_selected: {
        description:
          'The name of the selected Product Type for the Product bulk import process. Null if the Entity is Items',
        type: ['string', 'null'],
      },
      sellers_selected: {
        description:
          'The name of the selected Sellers for the Items bulk import process. Null if the Entity is Product',
        type: ['string', 'null'],
      },
    },
    required: [
      'previous_page_name',
      'page_name',
      'button_name',
      'entity_name',
      'operation_type',
      'sellers_selected',
      'product_type_selected',
      'bulk_importer_results',
      'country',
    ],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Bulk Import Submitted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'BulkImporterPageViewed' track call.
 *
 * @param BulkImporterPageViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bulkImporterPageViewed(
  props: BulkImporterPageViewed,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Bulk_Importer_Page_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Fired when the user views the Bulk Importer Page',
    properties: {
      country: { description: 'The afected country name', type: ['string', 'null'] },
      page_name: {
        description:
          'The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE',
        type: ['string', 'null'],
      },
      previous_page_name: {
        description:
          'The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE',
        type: ['string', 'null'],
      },
    },
    required: ['previous_page_name', 'page_name', 'country'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Bulk Importer Page Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'CategoryAddProductsEditionDiscarded' track call.
 *
 * @param CategoryAddProductsEditionDiscarded props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function categoryAddProductsEditionDiscarded(
  props: CategoryAddProductsEditionDiscarded,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Category_Add_Products_Edition_Discarded',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Fired when the user clicks on BACK button at the Add Products Modal',
    properties: {
      button_name: { description: 'The name of the button clicked', type: ['string', 'null'] },
      category_name: {
        description: 'The name of the category being created',
        type: ['string', 'null'],
      },
      category_path: {
        description:
          'The complete tier path of the category being created. Null if it is a Featured Collection category',
        type: ['string', 'null'],
      },
      category_status: {
        description: 'The status can be enabled or disabled. Permitted Values: DISABLE, ENABLE',
        enum: ['DISABLE', 'ENABLE', null],
        type: ['string', 'null'],
      },
      category_type: {
        description: 'The type of the category being created',
        type: ['string', 'null'],
      },
      country: { description: 'The afected country name', type: ['string', 'null'] },
      store_id: { description: 'The ID of the store', type: ['string', 'null'] },
      store_name: { description: 'The name of the store', type: ['string', 'null'] },
    },
    required: [
      'button_name',
      'category_name',
      'category_type',
      'category_path',
      'category_status',
      'country',
      'store_name',
      'store_id',
    ],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Category Add Products Edition Discarded',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'CategoryAddProductsEditionStarted' track call.
 *
 * @param CategoryAddProductsEditionStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function categoryAddProductsEditionStarted(
  props: CategoryAddProductsEditionStarted,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Category_Add_Products_Edition_Started',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Fired when the user clicks on ADD PRODUCTS button at the Category Details page',
    properties: {
      button_name: { description: 'The name of the button clicked', type: ['string', 'null'] },
      category_name: {
        description: 'The name of the category being created',
        type: ['string', 'null'],
      },
      category_path: {
        description:
          'The complete tier path of the category being created. Null if it is a Featured Collection category',
        type: ['string', 'null'],
      },
      category_status: {
        description: 'The status can be enabled or disabled. Permitted Values: DISABLE, ENABLE',
        enum: ['DISABLE', 'ENABLE', null],
        type: ['string', 'null'],
      },
      category_type: {
        description: 'The type of the category being created',
        type: ['string', 'null'],
      },
      country: { description: 'The afected country name', type: ['string', 'null'] },
      store_id: { description: 'The ID of the store', type: ['string', 'null'] },
      store_name: { description: 'The name of the store', type: ['string', 'null'] },
    },
    required: [
      'button_name',
      'category_name',
      'category_type',
      'category_path',
      'category_status',
      'country',
      'store_name',
      'store_id',
    ],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Category Add Products Edition Started',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'CategoryAddProductsEditionSubmitted' track call.
 *
 * @param CategoryAddProductsEditionSubmitted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function categoryAddProductsEditionSubmitted(
  props: CategoryAddProductsEditionSubmitted,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Category_Add_Products_Edition_Submitted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Fired when the user clicks on ADD SELECTED PRODUCTS button at the Add Products Modal',
    properties: {
      button_name: { description: 'The name of the button clicked', type: ['string', 'null'] },
      category_name: {
        description: 'The name of the category being created',
        type: ['string', 'null'],
      },
      category_path: {
        description:
          'The complete tier path of the category being created. Null if it is a Featured Collection category',
        type: ['string', 'null'],
      },
      category_status: {
        description: 'The status can be enabled or disabled. Permitted Values: DISABLE, ENABLE',
        enum: ['DISABLE', 'ENABLE', null],
        type: ['string', 'null'],
      },
      category_type: {
        description: 'The type of the category being created',
        type: ['string', 'null'],
      },
      country: { description: 'The afected country name', type: ['string', 'null'] },
      store_id: { description: 'The ID of the store', type: ['string', 'null'] },
      store_name: { description: 'The name of the store', type: ['string', 'null'] },
    },
    required: [
      'button_name',
      'category_name',
      'category_type',
      'category_path',
      'category_status',
      'country',
      'store_name',
      'store_id',
    ],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Category Add Products Edition Submitted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'CategoryAddProductsFilterApplied' track call.
 *
 * @param CategoryAddProductsFilterApplied props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function categoryAddProductsFilterApplied(
  props: CategoryAddProductsFilterApplied,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Category_Add_Products_Filter_Applied',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Fired when the user clicks on the Category Status Toggle to enable or disable a category at any point of the Category Edition flow.',
    properties: {
      add_products_filter_options: {
        description: 'The filter options selected by the user',
        enum: ['PRODUCT TYPE', 'BRAND', 'MANUFACTURER', null],
        type: ['string', 'null'],
      },
      button_name: { description: 'The name of the button clicked', type: ['string', 'null'] },
      category_name: {
        description: 'The name of the category being created',
        type: ['string', 'null'],
      },
      category_path: {
        description:
          'The complete tier path of the category being created. Null if it is a Featured Collection category',
        type: ['string', 'null'],
      },
      category_status: {
        description: 'The status can be enabled or disabled. Permitted Values: DISABLE, ENABLE',
        enum: ['DISABLE', 'ENABLE', null],
        type: ['string', 'null'],
      },
      category_type: {
        description: 'The type of the category being created',
        type: ['string', 'null'],
      },
      country: { description: 'The afected country name', type: ['string', 'null'] },
      store_id: { description: 'The ID of the store', type: ['string', 'null'] },
      store_name: { description: 'The name of the store', type: ['string', 'null'] },
    },
    required: [
      'button_name',
      'category_name',
      'category_type',
      'category_path',
      'category_status',
      'add_products_filter_options',
      'country',
      'store_name',
      'store_id',
    ],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Category Add Products Filter Applied',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'CategoryAddProductsSearch' track call.
 *
 * @param CategoryAddProductsSearch props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function categoryAddProductsSearch(
  props: CategoryAddProductsSearch,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Category_Add_Products_Search',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Fired when the user triggers the Product search engine by any means',
    properties: {
      add_products_search_text: {
        description: 'The search text being used when the event occured, if there is any',
        type: ['string', 'null'],
      },
      category_name: {
        description: 'The name of the category being created',
        type: ['string', 'null'],
      },
      category_path: {
        description:
          'The complete tier path of the category being created. Null if it is a Featured Collection category',
        type: ['string', 'null'],
      },
      category_status: {
        description: 'The status can be enabled or disabled. Permitted Values: DISABLE, ENABLE',
        enum: ['DISABLE', 'ENABLE', null],
        type: ['string', 'null'],
      },
      category_type: {
        description: 'The type of the category being created',
        type: ['string', 'null'],
      },
      country: { description: 'The afected country name', type: ['string', 'null'] },
      store_id: { description: 'The ID of the store', type: ['string', 'null'] },
      store_name: { description: 'The name of the store', type: ['string', 'null'] },
    },
    required: [
      'category_name',
      'category_type',
      'category_path',
      'category_status',
      'add_products_search_text',
      'country',
      'store_name',
      'store_id',
    ],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Category Add Products Search',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'CategoryChangeProductPositionDiscarded' track call.
 *
 * @param CategoryChangeProductPositionDiscarded props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function categoryChangeProductPositionDiscarded(
  props: CategoryChangeProductPositionDiscarded,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Category_Change_Product_Position_Discarded',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Fired when the user clicks on CANCEL option at the Product Table after Starts the Change Product Position flow and/or GO BACK or "X" button at the Confirm Product Position confirmation modal',
    properties: {
      button_name: { description: 'The name of the button clicked', type: ['string', 'null'] },
      category_name: {
        description: 'The name of the category being created',
        type: ['string', 'null'],
      },
      category_path: {
        description:
          'The complete tier path of the category being created. Null if it is a Featured Collection category',
        type: ['string', 'null'],
      },
      category_status: {
        description: 'The status can be enabled or disabled. Permitted Values: DISABLE, ENABLE',
        enum: ['DISABLE', 'ENABLE', null],
        type: ['string', 'null'],
      },
      category_type: {
        description: 'The type of the category being created',
        type: ['string', 'null'],
      },
      country: { description: 'The afected country name', type: ['string', 'null'] },
      store_id: { description: 'The ID of the store', type: ['string', 'null'] },
      store_name: { description: 'The name of the store', type: ['string', 'null'] },
    },
    required: [
      'button_name',
      'category_name',
      'category_type',
      'category_path',
      'category_status',
      'country',
      'store_name',
      'store_id',
    ],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Category Change Product Position Discarded',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'CategoryChangeProductPositionStarted' track call.
 *
 * @param CategoryChangeProductPositionStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function categoryChangeProductPositionStarted(
  props: CategoryChangeProductPositionStarted,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Category_Change_Product_Position_Started',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Fired when the user clicks on CHANGE PRODUCT POSITION option at the More options dropdown list at the Category Details Page',
    properties: {
      button_name: { description: 'The name of the button clicked', type: ['string', 'null'] },
      category_name: {
        description: 'The name of the category being created',
        type: ['string', 'null'],
      },
      category_path: {
        description:
          'The complete tier path of the category being created. Null if it is a Featured Collection category',
        type: ['string', 'null'],
      },
      category_status: {
        description: 'The status can be enabled or disabled. Permitted Values: DISABLE, ENABLE',
        enum: ['DISABLE', 'ENABLE', null],
        type: ['string', 'null'],
      },
      category_type: {
        description: 'The type of the category being created',
        type: ['string', 'null'],
      },
      country: { description: 'The afected country name', type: ['string', 'null'] },
      store_id: { description: 'The ID of the store', type: ['string', 'null'] },
      store_name: { description: 'The name of the store', type: ['string', 'null'] },
    },
    required: [
      'button_name',
      'category_name',
      'category_type',
      'category_path',
      'category_status',
      'country',
      'store_name',
      'store_id',
    ],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Category Change Product Position Started',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'CategoryChangeProductPositionSubmitted' track call.
 *
 * @param CategoryChangeProductPositionSubmitted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function categoryChangeProductPositionSubmitted(
  props: CategoryChangeProductPositionSubmitted,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Category_Change_Product_Position_Submitted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Fired when the user clicks on Confirm button at the Confirm Product Position confirmation modal',
    properties: {
      button_name: { description: 'The name of the button clicked', type: ['string', 'null'] },
      category_name: {
        description: 'The name of the category being created',
        type: ['string', 'null'],
      },
      category_path: {
        description:
          'The complete tier path of the category being created. Null if it is a Featured Collection category',
        type: ['string', 'null'],
      },
      category_status: {
        description: 'The status can be enabled or disabled. Permitted Values: DISABLE, ENABLE',
        enum: ['DISABLE', 'ENABLE', null],
        type: ['string', 'null'],
      },
      category_type: {
        description: 'The type of the category being created',
        type: ['string', 'null'],
      },
      country: { description: 'The afected country name', type: ['string', 'null'] },
      store_id: { description: 'The ID of the store', type: ['string', 'null'] },
      store_name: { description: 'The name of the store', type: ['string', 'null'] },
    },
    required: [
      'button_name',
      'category_name',
      'category_type',
      'category_path',
      'category_status',
      'country',
      'store_name',
      'store_id',
    ],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Category Change Product Position Submitted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'CategoryCreationDiscarded' track call.
 *
 * @param CategoryCreationDiscarded props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function categoryCreationDiscarded(
  props: CategoryCreationDiscarded,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Category_Creation_Discarded',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Fired when the user clicks on DISCARD button anytime during the category creation steps',
    properties: {
      button_name: { description: 'The name of the button clicked', type: ['string', 'null'] },
      country: { description: 'The afected country name', type: ['string', 'null'] },
      page_name: {
        description:
          'The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE',
        type: ['string', 'null'],
      },
      previous_page_name: {
        description:
          'The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE',
        type: ['string', 'null'],
      },
      store_id: { description: 'The ID of the store', type: ['string', 'null'] },
      store_name: { description: 'The name of the store', type: ['string', 'null'] },
    },
    required: [
      'button_name',
      'previous_page_name',
      'page_name',
      'country',
      'store_name',
      'store_id',
    ],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Category Creation Discarded',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'CategoryCreationStarted' track call.
 *
 * @param CategoryCreationStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function categoryCreationStarted(
  props: CategoryCreationStarted,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Category_Creation_Started',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Fired when the user clicks on ADD NEW CATEGORY button on the Category Tree page',
    properties: {
      button_name: { description: 'The name of the button clicked', type: ['string', 'null'] },
      country: { description: 'The afected country name', type: ['string', 'null'] },
      page_name: {
        description:
          'The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE',
        type: ['string', 'null'],
      },
      previous_page_name: {
        description:
          'The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE',
        type: ['string', 'null'],
      },
      store_id: { description: 'The ID of the store', type: ['string', 'null'] },
      store_name: { description: 'The name of the store', type: ['string', 'null'] },
    },
    required: [
      'button_name',
      'previous_page_name',
      'page_name',
      'country',
      'store_name',
      'store_id',
    ],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Category Creation Started',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'CategoryCreationSubmitted' track call.
 *
 * @param CategoryCreationSubmitted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function categoryCreationSubmitted(
  props: CategoryCreationSubmitted,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Category_Creation_Submitted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Fired when the user clicks on CREATE button at the last step to create a category',
    properties: {
      button_name: { description: 'The name of the button clicked', type: ['string', 'null'] },
      category_creation_results: {
        description:
          'Present the result of the success or error when create the category, in case the error brings the return message',
        type: ['string', 'null'],
      },
      category_name: {
        description: 'The name of the category being created',
        type: ['string', 'null'],
      },
      category_path: {
        description:
          'The complete tier path of the category being created. Null if it is a Featured Collection category',
        type: ['string', 'null'],
      },
      category_status: {
        description: 'The status can be enabled or disabled. Permitted Values: DISABLE, ENABLE',
        enum: ['DISABLE', 'ENABLE', null],
        type: ['string', 'null'],
      },
      category_type: {
        description: 'The type of the category being created',
        type: ['string', 'null'],
      },
      country: { description: 'The afected country name', type: ['string', 'null'] },
      store_id: { description: 'The ID of the store', type: ['string', 'null'] },
      store_name: { description: 'The name of the store', type: ['string', 'null'] },
    },
    required: [
      'button_name',
      'category_name',
      'category_type',
      'category_path',
      'category_status',
      'category_creation_results',
      'country',
      'store_name',
      'store_id',
    ],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Category Creation Submitted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'CategoryDetailsEditionDiscarded' track call.
 *
 * @param CategoryDetailsEditionDiscarded props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function categoryDetailsEditionDiscarded(
  props: CategoryDetailsEditionDiscarded,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Category_Details_Edition_Discarded',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Fired when the user clicks on CANCEL button at the Category Details Page',
    properties: {
      button_name: { description: 'The name of the button clicked', type: ['string', 'null'] },
      country: { description: 'The afected country name', type: ['string', 'null'] },
      page_name: {
        description:
          'The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE',
        type: ['string', 'null'],
      },
      previous_page_name: {
        description:
          'The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE',
        type: ['string', 'null'],
      },
      store_id: { description: 'The ID of the store', type: ['string', 'null'] },
      store_name: { description: 'The name of the store', type: ['string', 'null'] },
    },
    required: [
      'button_name',
      'previous_page_name',
      'page_name',
      'country',
      'store_name',
      'store_id',
    ],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Category Details Edition Discarded',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'CategoryDetailsEditionStarted' track call.
 *
 * @param CategoryDetailsEditionStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function categoryDetailsEditionStarted(
  props: CategoryDetailsEditionStarted,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Category_Details_Edition_Started',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Fired when the user clicks on Edit button (Pencil icon) on the Category Details page',
    properties: {
      button_name: { description: 'The name of the button clicked', type: ['string', 'null'] },
      category_name: {
        description: 'The name of the category being created',
        type: ['string', 'null'],
      },
      category_path: {
        description:
          'The complete tier path of the category being created. Null if it is a Featured Collection category',
        type: ['string', 'null'],
      },
      category_status: {
        description: 'The status can be enabled or disabled. Permitted Values: DISABLE, ENABLE',
        enum: ['DISABLE', 'ENABLE', null],
        type: ['string', 'null'],
      },
      category_type: {
        description: 'The type of the category being created',
        type: ['string', 'null'],
      },
      country: { description: 'The afected country name', type: ['string', 'null'] },
      page_name: {
        description:
          'The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE',
        type: ['string', 'null'],
      },
      previous_page_name: {
        description:
          'The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE',
        type: ['string', 'null'],
      },
      store_id: { description: 'The ID of the store', type: ['string', 'null'] },
      store_name: { description: 'The name of the store', type: ['string', 'null'] },
    },
    required: [
      'button_name',
      'previous_page_name',
      'page_name',
      'category_name',
      'category_type',
      'category_path',
      'category_status',
      'country',
      'store_name',
      'store_id',
    ],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Category Details Edition Started',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'CategoryDetailsEditionSubmitted' track call.
 *
 * @param CategoryDetailsEditionSubmitted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function categoryDetailsEditionSubmitted(
  props: CategoryDetailsEditionSubmitted,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Category_Details_Edition_Submitted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Fired when the user clicks on SAVE button at the Category Details page',
    properties: {
      button_name: { description: 'The name of the button clicked', type: ['string', 'null'] },
      category_name: {
        description: 'The name of the category being created',
        type: ['string', 'null'],
      },
      category_path: {
        description:
          'The complete tier path of the category being created. Null if it is a Featured Collection category',
        type: ['string', 'null'],
      },
      category_status: {
        description: 'The status can be enabled or disabled. Permitted Values: DISABLE, ENABLE',
        enum: ['DISABLE', 'ENABLE', null],
        type: ['string', 'null'],
      },
      category_type: {
        description: 'The type of the category being created',
        type: ['string', 'null'],
      },
      country: { description: 'The afected country name', type: ['string', 'null'] },
      page_name: {
        description:
          'The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE',
        type: ['string', 'null'],
      },
      previous_page_name: {
        description:
          'The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE',
        type: ['string', 'null'],
      },
      store_id: { description: 'The ID of the store', type: ['string', 'null'] },
      store_name: { description: 'The name of the store', type: ['string', 'null'] },
    },
    required: [
      'button_name',
      'previous_page_name',
      'page_name',
      'category_name',
      'category_type',
      'category_path',
      'category_status',
      'country',
      'store_name',
      'store_id',
    ],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Category Details Edition Submitted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'CategoryDetailsPageView' track call.
 *
 * @param CategoryDetailsPageView props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function categoryDetailsPageView(
  props: CategoryDetailsPageView,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Category_Details_Page_View',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Fired when the user views the Category Details Page',
    properties: {
      country: { description: 'The afected country name', type: ['string', 'null'] },
      page_name: {
        description:
          'The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE',
        type: ['string', 'null'],
      },
      previous_page_name: {
        description:
          'The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE',
        type: ['string', 'null'],
      },
      store_id: { description: 'The ID of the store', type: ['string', 'null'] },
      store_name: { description: 'The name of the store', type: ['string', 'null'] },
    },
    required: ['previous_page_name', 'page_name', 'country', 'store_name', 'store_id'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Category Details Page View',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'CategoryPositionEditionDiscarded' track call.
 *
 * @param CategoryPositionEditionDiscarded props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function categoryPositionEditionDiscarded(
  props: CategoryPositionEditionDiscarded,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Category_Position_Edition_Discarded',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Fired when the user clicks on CANCEL button at the EDIT CATEGORY POSITIONS flow',
    properties: {
      button_name: { description: 'The name of the button clicked', type: ['string', 'null'] },
      country: { description: 'The afected country name', type: ['string', 'null'] },
      page_name: {
        description:
          'The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE',
        type: ['string', 'null'],
      },
      previous_page_name: {
        description:
          'The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE',
        type: ['string', 'null'],
      },
      store_id: { description: 'The ID of the store', type: ['string', 'null'] },
      store_name: { description: 'The name of the store', type: ['string', 'null'] },
    },
    required: [
      'button_name',
      'previous_page_name',
      'page_name',
      'country',
      'store_name',
      'store_id',
    ],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Category Position Edition Discarded',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'CategoryPositionEditionStarted' track call.
 *
 * @param CategoryPositionEditionStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function categoryPositionEditionStarted(
  props: CategoryPositionEditionStarted,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Category_Position_Edition_Started',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Fired when the user clicks on EDIT CATEGORY POSITIONS button at the Category Organization page',
    properties: {
      button_name: { description: 'The name of the button clicked', type: ['string', 'null'] },
      country: { description: 'The afected country name', type: ['string', 'null'] },
      page_name: {
        description:
          'The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE',
        type: ['string', 'null'],
      },
      previous_page_name: {
        description:
          'The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE',
        type: ['string', 'null'],
      },
      store_id: { description: 'The ID of the store', type: ['string', 'null'] },
      store_name: { description: 'The name of the store', type: ['string', 'null'] },
    },
    required: [
      'button_name',
      'previous_page_name',
      'page_name',
      'country',
      'store_name',
      'store_id',
    ],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Category Position Edition Started',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'CategoryPositionEditionSubmitted' track call.
 *
 * @param CategoryPositionEditionSubmitted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function categoryPositionEditionSubmitted(
  props: CategoryPositionEditionSubmitted,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Category_Position_Edition_Submitted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Fired when the user clicks on SAVE button at the EDIT CATEGORY POSITIONS flow',
    properties: {
      button_name: { description: 'The name of the button clicked', type: ['string', 'null'] },
      country: { description: 'The afected country name', type: ['string', 'null'] },
      page_name: {
        description:
          'The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE',
        type: ['string', 'null'],
      },
      previous_page_name: {
        description:
          'The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE',
        type: ['string', 'null'],
      },
      store_id: { description: 'The ID of the store', type: ['string', 'null'] },
      store_name: { description: 'The name of the store', type: ['string', 'null'] },
    },
    required: [
      'button_name',
      'previous_page_name',
      'page_name',
      'country',
      'store_name',
      'store_id',
    ],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Category Position Edition Submitted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'CategoryStatusToggleChanged' track call.
 *
 * @param CategoryStatusToggleChanged props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function categoryStatusToggleChanged(
  props: CategoryStatusToggleChanged,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Category_Status_Toggle_Changed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Fired when the user clicks on the Category Status Toggle to enable or disable a category at any point of the Category Edition flow',
    properties: {
      button_name: { description: 'The name of the button clicked', type: ['string', 'null'] },
      category_name: {
        description: 'The name of the category being created',
        type: ['string', 'null'],
      },
      category_path: {
        description:
          'The complete tier path of the category being created. Null if it is a Featured Collection category',
        type: ['string', 'null'],
      },
      category_previous_status: {
        description: 'The category status before the user changes it',
        enum: ['DISABLE', 'ENABLE', null],
        type: ['string', 'null'],
      },
      category_status: {
        description: 'The status can be enabled or disabled. Permitted Values: DISABLE, ENABLE',
        enum: ['DISABLE', 'ENABLE', null],
        type: ['string', 'null'],
      },
      category_type: {
        description: 'The type of the category being created',
        type: ['string', 'null'],
      },
      country: { description: 'The afected country name', type: ['string', 'null'] },
      store_id: { description: 'The ID of the store', type: ['string', 'null'] },
      store_name: { description: 'The name of the store', type: ['string', 'null'] },
    },
    required: [
      'button_name',
      'category_name',
      'category_type',
      'category_path',
      'category_status',
      'category_previous_status',
      'country',
      'store_name',
      'store_id',
    ],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Category Status Toggle Changed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'CategoryTreePageViewed' track call.
 *
 * @param CategoryTreePageViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function categoryTreePageViewed(
  props: CategoryTreePageViewed,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Category_Tree_Page_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Fired when the user views the Category Tree Page',
    properties: {
      country: { description: 'The afected country name', type: ['string', 'null'] },
      page_name: {
        description:
          'The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE',
        type: ['string', 'null'],
      },
      previous_page_name: {
        description:
          'The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE',
        type: ['string', 'null'],
      },
      store_id: { description: 'The ID of the store', type: ['string', 'null'] },
      store_name: { description: 'The name of the store', type: ['string', 'null'] },
    },
    required: ['page_name', 'previous_page_name', 'store_name', 'country', 'store_id'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Category Tree Page Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'CategoryUnassignProductDiscarded' track call.
 *
 * @param CategoryUnassignProductDiscarded props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function categoryUnassignProductDiscarded(
  props: CategoryUnassignProductDiscarded,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Category_Unassign_Product_Discarded',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Fired when the user clicks on CANCEL option at the Product Table after Starts the Unassing Product flow and/or NO, GO BACK or "X" button at the Remove products form this category confirmation modal',
    properties: {
      button_name: { description: 'The name of the button clicked', type: ['string', 'null'] },
      category_name: {
        description: 'The name of the category being created',
        type: ['string', 'null'],
      },
      category_path: {
        description:
          'The complete tier path of the category being created. Null if it is a Featured Collection category',
        type: ['string', 'null'],
      },
      category_status: {
        description: 'The status can be enabled or disabled. Permitted Values: DISABLE, ENABLE',
        enum: ['DISABLE', 'ENABLE', null],
        type: ['string', 'null'],
      },
      category_type: {
        description: 'The type of the category being created',
        type: ['string', 'null'],
      },
      country: { description: 'The afected country name', type: ['string', 'null'] },
      store_id: { description: 'The ID of the store', type: ['string', 'null'] },
      store_name: { description: 'The name of the store', type: ['string', 'null'] },
    },
    required: [
      'button_name',
      'category_name',
      'category_type',
      'category_path',
      'category_status',
      'country',
      'store_name',
      'store_id',
    ],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Category Unassign Product Discarded',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'CategoryUnassignProductStarted' track call.
 *
 * @param CategoryUnassignProductStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function categoryUnassignProductStarted(
  props: CategoryUnassignProductStarted,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Category_Unassign_Product_Started',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Fired when the user clicks on UNASSIGN PRODUCTS option at the More options dropdown list at the Category Details Page',
    properties: {
      button_name: { description: 'The name of the button clicked', type: ['string', 'null'] },
      category_name: {
        description: 'The name of the category being created',
        type: ['string', 'null'],
      },
      category_path: {
        description:
          'The complete tier path of the category being created. Null if it is a Featured Collection category',
        type: ['string', 'null'],
      },
      category_status: {
        description: 'The status can be enabled or disabled. Permitted Values: DISABLE, ENABLE',
        enum: ['DISABLE', 'ENABLE', null],
        type: ['string', 'null'],
      },
      category_type: {
        description: 'The type of the category being created',
        type: ['string', 'null'],
      },
      country: { description: 'The afected country name', type: ['string', 'null'] },
      store_id: { description: 'The ID of the store', type: ['string', 'null'] },
      store_name: { description: 'The name of the store', type: ['string', 'null'] },
    },
    required: [
      'button_name',
      'category_name',
      'category_type',
      'category_path',
      'category_status',
      'country',
      'store_name',
      'store_id',
    ],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Category Unassign Product Started',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'CategoryUnassignProductSubmitted' track call.
 *
 * @param CategoryUnassignProductSubmitted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function categoryUnassignProductSubmitted(
  props: CategoryUnassignProductSubmitted,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Category_Unassign_Product_Submitted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Fired when the user clicks on YES, REMOVE button at the Remove products form this category confirmation modal',
    properties: {
      button_name: { description: 'The name of the button clicked', type: ['string', 'null'] },
      category_name: {
        description: 'The name of the category being created',
        type: ['string', 'null'],
      },
      category_path: {
        description:
          'The complete tier path of the category being created. Null if it is a Featured Collection category',
        type: ['string', 'null'],
      },
      category_status: {
        description: 'The status can be enabled or disabled. Permitted Values: DISABLE, ENABLE',
        enum: ['DISABLE', 'ENABLE', null],
        type: ['string', 'null'],
      },
      category_type: {
        description: 'The type of the category being created',
        type: ['string', 'null'],
      },
      country: { description: 'The afected country name', type: ['string', 'null'] },
      store_id: { description: 'The ID of the store', type: ['string', 'null'] },
      store_name: { description: 'The name of the store', type: ['string', 'null'] },
    },
    required: [
      'button_name',
      'category_name',
      'category_type',
      'category_path',
      'category_status',
      'country',
      'store_name',
      'store_id',
    ],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Category Unassign Product Submitted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'ItemDetailsPageViewed' track call.
 *
 * @param ItemDetailsPageViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function itemDetailsPageViewed(
  props: ItemDetailsPageViewed,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Item_Details_Page_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Fired when the user views the Item Details Page',
    properties: {
      country: { description: 'The afected country name', type: ['string', 'null'] },
      page_name: {
        description:
          'The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE',
        type: ['string', 'null'],
      },
      previous_page_name: {
        description:
          'The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE',
        type: ['string', 'null'],
      },
    },
    required: ['page_name', 'previous_page_name', 'country'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Item Details Page Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'ItemFilterInteraction' track call.
 *
 * @param ItemFilterInteraction props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function itemFilterInteraction(
  props: ItemFilterInteraction,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Item_Filter_Interaction',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Fired when the user clicks on APPLY at the Item filter modal',
    properties: {
      country: { description: 'The afected country name', type: ['string', 'null'] },
      item_filter_query: {
        description: 'The filter query entered by the user.',
        type: ['string', 'null'],
      },
      item_filter_result_count: {
        description: 'How many items were on the filtered list',
        type: ['integer', 'null'],
      },
      page_name: {
        description:
          'The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE',
        type: ['string', 'null'],
      },
      previous_page_name: {
        description:
          'The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE',
        type: ['string', 'null'],
      },
      vendor_id: { description: 'The Vendor ID of the item', type: ['string', 'null'] },
      vendor_name: { description: 'The Vendor name of the Item', type: ['string', 'null'] },
    },
    required: [
      'previous_page_name',
      'page_name',
      'item_filter_query',
      'item_filter_result_count',
      'country',
      'vendor_name',
      'vendor_id',
    ],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Item Filter Interaction',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'ItemInformationEditionDiscarded' track call.
 *
 * @param ItemInformationEditionDiscarded props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function itemInformationEditionDiscarded(
  props: ItemInformationEditionDiscarded,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Item_Information_Edition_Discarded',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Fired when the user clicks on CANCEL button anytime during the Item Information edition.',
    properties: {
      button_name: { description: 'The name of the button clicked', type: ['string', 'null'] },
      country: { description: 'The afected country name', type: ['string', 'null'] },
      page_name: {
        description:
          'The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE',
        type: ['string', 'null'],
      },
      previous_page_name: {
        description:
          'The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE',
        type: ['string', 'null'],
      },
    },
    required: ['country', 'previous_page_name', 'page_name', 'button_name'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Item Information Edition Discarded',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'ItemInformationEditionStarted' track call.
 *
 * @param ItemInformationEditionStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function itemInformationEditionStarted(
  props: ItemInformationEditionStarted,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Item_Information_Edition_Started',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Fired when the user clicks on Edit button (Pencil icon) on the Item information section at the Item details page.',
    properties: {
      button_name: { description: 'The name of the button clicked', type: ['string', 'null'] },
      country: { description: 'The afected country name', type: ['string', 'null'] },
      item_status: {
        description: 'The status can be enable or disable.',
        type: ['string', 'null'],
      },
      page_name: {
        description:
          'The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE',
        type: ['string', 'null'],
      },
      previous_page_name: {
        description:
          'The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE',
        type: ['string', 'null'],
      },
      product_name: {
        description: 'The name of the variant to be created',
        type: ['string', 'null'],
      },
      seller_id: {
        description: 'Identifier that distinguishes sellers on the platform.',
        type: ['integer', 'null'],
      },
      seller_name: {
        description: 'The seller´s name of the product to be edited.',
        type: ['string', 'null'],
      },
      sku: { description: 'The SKU number.', type: ['integer', 'null'] },
      variant_gtin: {
        description: 'The GTIN is the number used to globally identify the product',
        type: ['integer', 'null'],
      },
    },
    required: [
      'country',
      'previous_page_name',
      'page_name',
      'product_name',
      'variant_gtin',
      'item_status',
      'seller_name',
      'seller_id',
      'sku',
      'button_name',
    ],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Item Information Edition Started',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'ItemInformationEditionSubmitted' track call.
 *
 * @param ItemInformationEditionSubmitted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function itemInformationEditionSubmitted(
  props: ItemInformationEditionSubmitted,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Item_Information_Edition_Submitted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Fired when the user clicks on Save changes button on the Item Information section at the Item details page and/or clicks on the Update button at the confirmation modal to update the SKU number.',
    properties: {
      button_name: { description: 'The name of the button clicked', type: ['string', 'null'] },
      country: { description: 'The afected country name', type: ['string', 'null'] },
      item_status: {
        description: 'The status can be enable or disable.',
        type: ['string', 'null'],
      },
      page_name: {
        description:
          'The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE',
        type: ['string', 'null'],
      },
      previous_page_name: {
        description:
          'The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE',
        type: ['string', 'null'],
      },
      product_name: {
        description: 'The name of the variant to be created',
        type: ['string', 'null'],
      },
      seller_id: {
        description: 'Identifier that distinguishes sellers on the platform.',
        type: ['integer', 'null'],
      },
      seller_name: {
        description: 'The seller´s name of the product to be edited.',
        type: ['string', 'null'],
      },
      sku: { description: 'The SKU number.', type: ['integer', 'null'] },
      variant_gtin: {
        description: 'The GTIN is the number used to globally identify the product',
        type: ['integer', 'null'],
      },
    },
    required: [
      'country',
      'previous_page_name',
      'page_name',
      'product_name',
      'variant_gtin',
      'item_status',
      'seller_name',
      'seller_id',
      'sku',
      'button_name',
    ],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Item Information Edition Submitted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'ItemListingPageViewed' track call.
 *
 * @param ItemListingPageViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function itemListingPageViewed(
  props: ItemListingPageViewed,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Item_Listing_Page_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Fired when the user views the Item Listing Page',
    properties: {
      country: { description: 'The afected country name', type: ['string', 'null'] },
      page_name: {
        description:
          'The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE',
        type: ['string', 'null'],
      },
      previous_page_name: {
        description:
          'The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE',
        type: ['string', 'null'],
      },
    },
    required: ['page_name', 'previous_page_name', 'country'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Item Listing Page Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'ItemSearch' track call.
 *
 * @param ItemSearch props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function itemSearch(props: ItemSearch, options?: Options, callback?: Callback): void {
  const schema = {
    $id: 'Item_Search',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Fired when the user triggers the Item search engine by any means',
    properties: {
      country: { description: 'The afected country name', type: ['string', 'null'] },
      item_search_query: {
        description: 'The search query entered by the user.',
        type: ['string', 'null'],
      },
      item_search_result_count: {
        description: 'How many items were on the search resulting list',
        type: ['integer', 'null'],
      },
      page_name: {
        description:
          'The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE',
        type: ['string', 'null'],
      },
      previous_page_name: {
        description:
          'The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE',
        type: ['string', 'null'],
      },
      vendor_id: { description: 'The Vendor ID of the item', type: ['string', 'null'] },
      vendor_name: { description: 'The Vendor name of the Item', type: ['string', 'null'] },
    },
    required: [
      'previous_page_name',
      'page_name',
      'item_search_query',
      'item_search_result_count',
      'country',
      'vendor_name',
      'vendor_id',
    ],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Item Search',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'ItemTypeEditionDiscarded' track call.
 *
 * @param ItemTypeEditionDiscarded props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function itemTypeEditionDiscarded(
  props: ItemTypeEditionDiscarded,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Item_Type_Edition_Discarded',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Fired when the user clicks on CANCEL button anytime during the Item Type edition.',
    properties: {
      button_name: { description: 'The name of the button clicked', type: ['string', 'null'] },
      country: { description: 'The afected country name', type: ['string', 'null'] },
      page_name: {
        description:
          'The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE',
        type: ['string', 'null'],
      },
      previous_page_name: {
        description:
          'The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE',
        type: ['string', 'null'],
      },
    },
    required: ['country', 'previous_page_name', 'page_name', 'button_name'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Item Type Edition Discarded',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'ItemTypeEditionStarted' track call.
 *
 * @param ItemTypeEditionStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function itemTypeEditionStarted(
  props: ItemTypeEditionStarted,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Item_Type_Edition_Started',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Fired when the user clicks on Edit button (Pencil icon) on the Item type section at the Item details page.',
    properties: {
      button_name: { description: 'The name of the button clicked', type: ['string', 'null'] },
      country: { description: 'The afected country name', type: ['string', 'null'] },
      item_status: {
        description: 'The status can be enable or disable.',
        type: ['string', 'null'],
      },
      item_type_attributes: {
        description: 'The attributes and values at the start of the edition process.',
        type: ['string', 'null'],
      },
      page_name: {
        description:
          'The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE',
        type: ['string', 'null'],
      },
      previous_page_name: {
        description:
          'The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE',
        type: ['string', 'null'],
      },
      product_name: {
        description: 'The name of the variant to be created',
        type: ['string', 'null'],
      },
      seller_id: {
        description: 'Identifier that distinguishes sellers on the platform.',
        type: ['integer', 'null'],
      },
      seller_name: {
        description: 'The seller´s name of the product to be edited.',
        type: ['string', 'null'],
      },
      sku: { description: 'The SKU number.', type: ['integer', 'null'] },
      variant_gtin: {
        description: 'The GTIN is the number used to globally identify the product',
        type: ['integer', 'null'],
      },
    },
    required: [
      'country',
      'previous_page_name',
      'page_name',
      'product_name',
      'variant_gtin',
      'item_status',
      'seller_name',
      'seller_id',
      'sku',
      'item_type_attributes',
      'button_name',
    ],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Item Type Edition Started',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'ItemTypeEditionSubmitted' track call.
 *
 * @param ItemTypeEditionSubmitted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function itemTypeEditionSubmitted(
  props: ItemTypeEditionSubmitted,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Item_Type_Edition_Submitted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Fired when the user clicks on CANCEL button anytime during the Item Type edition.',
    properties: {
      button_name: { description: 'The name of the button clicked', type: ['string', 'null'] },
      country: { description: 'The afected country name', type: ['string', 'null'] },
      page_name: {
        description:
          'The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE',
        type: ['string', 'null'],
      },
      previous_page_name: {
        description:
          'The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE',
        type: ['string', 'null'],
      },
    },
    required: ['country', 'previous_page_name', 'page_name', 'button_name'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Item Type Edition Submitted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'ProductCreationDiscarded' track call.
 *
 * @param ProductCreationDiscarded props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function productCreationDiscarded(
  props: ProductCreationDiscarded,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Product_Creation_Discarded',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Fired when the user clicks on DISCARD button anytime during the product creation steps',
    properties: {
      button_name: { description: 'The name of the button clicked', type: ['string', 'null'] },
      country: { description: 'The afected country name', type: ['string', 'null'] },
      page_name: {
        description:
          'The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE',
        type: ['string', 'null'],
      },
      previous_page_name: {
        description:
          'The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE',
        type: ['string', 'null'],
      },
    },
    required: ['country', 'previous_page_name', 'page_name', 'button_name'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Product Creation Discarded',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'ProductCreationStarted' track call.
 *
 * @param ProductCreationStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function productCreationStarted(
  props: ProductCreationStarted,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Product_Creation_Started',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Fired when the user clicks on CREATE PRODUCT button on the Product listing page',
    properties: {
      button_name: { description: 'The name of the button clicked', type: ['string', 'null'] },
      country: { description: 'The afected country name', type: ['string', 'null'] },
      page_name: {
        description:
          'The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE',
        type: ['string', 'null'],
      },
      previous_page_name: {
        description:
          'The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE',
        type: ['string', 'null'],
      },
    },
    required: ['country', 'button_name', 'previous_page_name', 'page_name'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Product Creation Started',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'ProductCreationSubmitted' track call.
 *
 * @param ProductCreationSubmitted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function productCreationSubmitted(
  props: ProductCreationSubmitted,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Product_Creation_Submitted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Fired when the user clicks on CREATE button at the last step to create the product',
    properties: {
      button_name: { description: 'The name of the button clicked', type: ['string', 'null'] },
      country: { description: 'The afected country name', type: ['string', 'null'] },
      page_name: {
        description:
          'The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE',
        type: ['string', 'null'],
      },
      previous_page_name: {
        description:
          'The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE',
        type: ['string', 'null'],
      },
      product_brand: {
        description:
          'A brand is a distinct representation of a product, service or company through a symbolic name, design, or any unique characteristic that identifies it in the market',
        type: ['string', 'null'],
      },
      product_image: {
        description: 'It must be an optinal reference if the variant is beeing created with image',
        type: ['object', 'null'],
      },
      product_manufacture: {
        description: 'A manufacturer is a company or entity that produces or creates products',
        type: ['string', 'null'],
      },
      product_name: {
        description: 'The name of the variant to be created',
        type: ['string', 'null'],
      },
      product_result: {
        description:
          'Present the result of the success or error when registering the product, in case the error brings the return message',
        type: ['string', 'null'],
      },
      product_status: {
        description: 'The status can be enable or disable',
        enum: ['DISABLE', 'ENABLE', null],
        type: ['string', 'null'],
      },
      variant_gtin: {
        description: 'The GTIN is the number used to globally identify the product',
        type: ['integer', 'null'],
      },
    },
    required: [
      'button_name',
      'page_name',
      'product_brand',
      'variant_gtin',
      'product_image',
      'product_manufacture',
      'product_name',
      'product_result',
      'product_status',
      'previous_page_name',
      'country',
    ],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Product Creation Submitted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'ProductDetailsPageViewed' track call.
 *
 * @param ProductDetailsPageViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function productDetailsPageViewed(
  props: ProductDetailsPageViewed,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Product_Details_Page_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Fired when the user views the Product Details Page',
    properties: {
      country: { description: 'The afected country name', type: ['string', 'null'] },
      page_name: {
        description:
          'The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE',
        type: ['string', 'null'],
      },
      previous_page_name: {
        description:
          'The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE',
        type: ['string', 'null'],
      },
    },
    required: ['page_name', 'previous_page_name', 'country'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Product Details Page Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'ProductImageEditionSubmitted' track call.
 *
 * @param ProductImageEditionSubmitted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function productImageEditionSubmitted(
  props: ProductImageEditionSubmitted,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Product_Image_Edition_Submitted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Fired when the user changes the Product Image on the Product Image section at the Products details page',
    properties: {
      button_name: { description: 'The name of the button clicked', type: ['string', 'null'] },
      country: { description: 'The afected country name', type: ['string', 'null'] },
      page_name: {
        description:
          'The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE',
        type: ['string', 'null'],
      },
      previous_page_name: {
        description:
          'The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE',
        type: ['string', 'null'],
      },
      product_brand: {
        description:
          'A brand is a distinct representation of a product, service or company through a symbolic name, design, or any unique characteristic that identifies it in the market',
        type: ['string', 'null'],
      },
      product_description: {
        description: 'The text of the product description generated by the AI tools',
        type: ['string', 'null'],
      },
      product_image: {
        description: 'It must be an optinal reference if the variant is beeing created with image',
        type: ['object', 'null'],
      },
      product_manufacture: {
        description: 'A manufacturer is a company or entity that produces or creates products',
        type: ['string', 'null'],
      },
      product_name: {
        description: 'The name of the variant to be created',
        type: ['string', 'null'],
      },
      product_status: {
        description: 'The status can be enable or disable',
        enum: ['DISABLE', 'ENABLE', null],
        type: ['string', 'null'],
      },
    },
    required: [
      'country',
      'button_name',
      'previous_page_name',
      'product_name',
      'product_brand',
      'product_manufacture',
      'product_description',
      'product_status',
      'product_image',
      'page_name',
    ],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Product Image Edition Submitted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'ProductInformationEditionDiscarded' track call.
 *
 * @param ProductInformationEditionDiscarded props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function productInformationEditionDiscarded(
  props: ProductInformationEditionDiscarded,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Product_Information_Edition_Discarded',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Fired when the user clicks on CANCEL button anytime during the product information edition',
    properties: {
      button_name: { description: 'The name of the button clicked', type: ['string', 'null'] },
      country: { description: 'The afected country name', type: ['string', 'null'] },
      page_name: {
        description:
          'The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE',
        type: ['string', 'null'],
      },
      previous_page_name: {
        description:
          'The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE',
        type: ['string', 'null'],
      },
    },
    required: ['country', 'previous_page_name', 'page_name', 'button_name'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Product Information Edition Discarded',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'ProductInformationEditionStarted' track call.
 *
 * @param ProductInformationEditionStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function productInformationEditionStarted(
  props: ProductInformationEditionStarted,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Product_Information_Edition_Started',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Fired when the user clicks on Edit button (Pencil icon) on the Product information section at the Products details page',
    properties: {
      button_name: { description: 'The name of the button clicked', type: ['string', 'null'] },
      country: { description: 'The afected country name', type: ['string', 'null'] },
      page_name: {
        description:
          'The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE',
        type: ['string', 'null'],
      },
      previous_page_name: {
        description:
          'The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE',
        type: ['string', 'null'],
      },
      product_brand: {
        description:
          'A brand is a distinct representation of a product, service or company through a symbolic name, design, or any unique characteristic that identifies it in the market',
        type: ['string', 'null'],
      },
      product_description: {
        description: 'The text of the product description generated by the AI tools',
        type: ['string', 'null'],
      },
      product_manufacture: {
        description: 'A manufacturer is a company or entity that produces or creates products',
        type: ['string', 'null'],
      },
      product_name: {
        description: 'The name of the variant to be created',
        type: ['string', 'null'],
      },
      product_status: {
        description: 'The status can be enable or disable',
        enum: ['DISABLE', 'ENABLE', null],
        type: ['string', 'null'],
      },
    },
    required: [
      'country',
      'button_name',
      'previous_page_name',
      'product_name',
      'product_brand',
      'product_manufacture',
      'product_description',
      'product_status',
      'page_name',
    ],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Product Information Edition Started',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'ProductInformationEditionSubmitted' track call.
 *
 * @param ProductInformationEditionSubmitted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function productInformationEditionSubmitted(
  props: ProductInformationEditionSubmitted,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Product_Information_Edition_Submitted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Fired when the user clicks on Save changes button on the Product information section at the Products details page',
    properties: {
      button_name: { description: 'The name of the button clicked', type: ['string', 'null'] },
      country: { description: 'The afected country name', type: ['string', 'null'] },
      page_name: {
        description:
          'The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE',
        type: ['string', 'null'],
      },
      previous_page_name: {
        description:
          'The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE',
        type: ['string', 'null'],
      },
      product_brand: {
        description:
          'A brand is a distinct representation of a product, service or company through a symbolic name, design, or any unique characteristic that identifies it in the market',
        type: ['string', 'null'],
      },
      product_description: {
        description: 'The text of the product description generated by the AI tools',
        type: ['string', 'null'],
      },
      product_manufacture: {
        description: 'A manufacturer is a company or entity that produces or creates products',
        type: ['string', 'null'],
      },
      product_name: {
        description: 'The name of the variant to be created',
        type: ['string', 'null'],
      },
      product_status: {
        description: 'The status can be enable or disable',
        enum: ['DISABLE', 'ENABLE', null],
        type: ['string', 'null'],
      },
    },
    required: [
      'country',
      'button_name',
      'previous_page_name',
      'product_name',
      'product_brand',
      'product_manufacture',
      'product_description',
      'product_status',
      'page_name',
    ],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Product Information Edition Submitted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'ProductListingPageViewed' track call.
 *
 * @param ProductListingPageViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function productListingPageViewed(
  props: ProductListingPageViewed,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Product_Listing_Page_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Fired when the user views the Product Listing Page',
    properties: {
      country: { description: 'The afected country name', type: ['string', 'null'] },
      page_name: {
        description:
          'The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE',
        type: ['string', 'null'],
      },
      previous_page_name: {
        description:
          'The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE',
        type: ['string', 'null'],
      },
    },
    required: ['page_name', 'previous_page_name', 'country'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Product Listing Page Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'ProductTypeValuesEditionDiscarded' track call.
 *
 * @param ProductTypeValuesEditionDiscarded props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function productTypeValuesEditionDiscarded(
  props: ProductTypeValuesEditionDiscarded,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Product_Type_Values_Edition_Discarded',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Fired when the user clicks on CANCEL button anytime during the product type values edition',
    properties: {
      button_name: { description: 'The name of the button clicked', type: ['string', 'null'] },
      country: { description: 'The afected country name', type: ['string', 'null'] },
      page_name: {
        description:
          'The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE',
        type: ['string', 'null'],
      },
      previous_page_name: {
        description:
          'The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE',
        type: ['string', 'null'],
      },
    },
    required: ['country', 'button_name', 'previous_page_name', 'page_name'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Product Type Values Edition Discarded',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'ProductTypeValuesEditionStarted' track call.
 *
 * @param ProductTypeValuesEditionStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function productTypeValuesEditionStarted(
  props: ProductTypeValuesEditionStarted,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Product_Type_Values_Edition_Started',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Retrieving data. Wait a few seconds and try to cut or copy again.',
    properties: {
      button_name: { description: 'The name of the button clicked', type: ['string', 'null'] },
      country: { description: 'The afected country name', type: ['string', 'null'] },
      page_name: {
        description:
          'The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE',
        type: ['string', 'null'],
      },
      previous_page_name: {
        description:
          'The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE',
        type: ['string', 'null'],
      },
      product_brand: {
        description:
          'A brand is a distinct representation of a product, service or company through a symbolic name, design, or any unique characteristic that identifies it in the market',
        type: ['string', 'null'],
      },
      product_description: {
        description: 'The text of the product description generated by the AI tools',
        type: ['string', 'null'],
      },
      product_manufacture: {
        description: 'A manufacturer is a company or entity that produces or creates products',
        type: ['string', 'null'],
      },
      product_name: {
        description: 'The name of the variant to be created',
        type: ['string', 'null'],
      },
      product_status: {
        description: 'The status can be enable or disable',
        enum: ['DISABLE', 'ENABLE', null],
        type: ['string', 'null'],
      },
      product_type_attributes: {
        description: 'The attributes and values at the start of the edition process',
        type: ['string', 'null'],
      },
    },
    required: [
      'country',
      'button_name',
      'previous_page_name',
      'product_name',
      'product_brand',
      'product_manufacture',
      'product_description',
      'product_status',
      'product_type_attributes',
      'page_name',
    ],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Product Type Values Edition Started',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'ProductTypeValuesEditionSubmitted' track call.
 *
 * @param ProductTypeValuesEditionSubmitted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function productTypeValuesEditionSubmitted(
  props: ProductTypeValuesEditionSubmitted,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Product_Type_Values_Edition_Submitted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Fired when the user clicks on Save changes button on the Product Type section at the Products details page',
    properties: {
      button_name: { description: 'The name of the button clicked', type: ['string', 'null'] },
      country: { description: 'The afected country name', type: ['string', 'null'] },
      page_name: {
        description:
          'The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE',
        type: ['string', 'null'],
      },
      previous_page_name: {
        description:
          'The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE',
        type: ['string', 'null'],
      },
      product_brand: {
        description:
          'A brand is a distinct representation of a product, service or company through a symbolic name, design, or any unique characteristic that identifies it in the market',
        type: ['string', 'null'],
      },
      product_description: {
        description: 'The text of the product description generated by the AI tools',
        type: ['string', 'null'],
      },
      product_manufacture: {
        description: 'A manufacturer is a company or entity that produces or creates products',
        type: ['string', 'null'],
      },
      product_name: {
        description: 'The name of the variant to be created',
        type: ['string', 'null'],
      },
      product_status: {
        description: 'The status can be enable or disable',
        enum: ['DISABLE', 'ENABLE', null],
        type: ['string', 'null'],
      },
      product_type_attributes: {
        description: 'The attributes and values at the start of the edition process',
        type: ['string', 'null'],
      },
    },
    required: [
      'country',
      'button_name',
      'previous_page_name',
      'product_name',
      'product_brand',
      'product_manufacture',
      'product_description',
      'product_status',
      'product_type_attributes',
      'page_name',
    ],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Product Type Values Edition Submitted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'VariantCreationDiscarded' track call.
 *
 * @param VariantCreationDiscarded props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function variantCreationDiscarded(
  props: VariantCreationDiscarded,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Variant_Creation_Discarded',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Fired when the user clicks on YES, CANCEL button on the modal anytime during the variant creation steps',
    properties: {
      button_name: { description: 'The name of the button clicked', type: ['string', 'null'] },
      country: { description: 'The afected country name', type: ['string', 'null'] },
      page_name: {
        description:
          'The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE',
        type: ['string', 'null'],
      },
      previous_page_name: {
        description:
          'The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE',
        type: ['string', 'null'],
      },
    },
    required: ['button_name', 'previous_page_name', 'page_name', 'country'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Variant Creation Discarded',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'VariantCreationStarted' track call.
 *
 * @param VariantCreationStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function variantCreationStarted(
  props: VariantCreationStarted,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Variant_Creation_Started',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Fired when the user clicks on CREATE VARIANT icon on the product details page',
    properties: {
      button_name: { description: 'The name of the button clicked', type: ['string', 'null'] },
      country: { description: 'The afected country name', type: ['string', 'null'] },
      page_name: {
        description:
          'The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE',
        type: ['string', 'null'],
      },
      previous_page_name: {
        description:
          'The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE',
        type: ['string', 'null'],
      },
    },
    required: ['button_name', 'previous_page_name', 'page_name', 'country'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Variant Creation Started',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'VariantCreationSubmitted' track call.
 *
 * @param VariantCreationSubmitted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function variantCreationSubmitted(
  props: VariantCreationSubmitted,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Variant_Creation_Submitted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Fired when the user clicks on CREATE button at the last step to create the variant AND when the user clicks on CREATE button at the last step to create the product',
    properties: {
      country: { description: 'The afected country name', type: ['string', 'null'] },
      page_name: {
        description:
          'The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE',
        type: ['string', 'null'],
      },
      previous_page_name: {
        description:
          'The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE',
        type: ['string', 'null'],
      },
      variant_gtin: {
        description: 'The GTIN is the number used to globally identify the product',
        type: ['integer', 'null'],
      },
      variant_image: {
        description: 'It must be an optinal reference if the variant is beeing created with image',
        type: ['object', 'null'],
      },
      variant_name: {
        description: 'The name of the variant to be created',
        type: ['string', 'null'],
      },
      variant_result: {
        description:
          'Present the result of the success or error when registering the variant, in case the error brings the return message',
        type: ['string', 'null'],
      },
      variant_status: {
        description: 'The status can be enable or disable',
        enum: ['DISABLE', 'ENABLE', null],
        type: ['string', 'null'],
      },
    },
    required: [
      'country',
      'previous_page_name',
      'page_name',
      'variant_gtin',
      'variant_image',
      'variant_name',
      'variant_result',
      'variant_status',
    ],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Variant Creation Submitted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'VariantDetailsEditionDiscarded' track call.
 *
 * @param VariantDetailsEditionDiscarded props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function variantDetailsEditionDiscarded(
  props: VariantDetailsEditionDiscarded,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Variant_Details_Edition_Discarded',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Fired when the user clicks on CANCEL button anytime during the Variant details edition',
    properties: {
      button_name: { description: 'The name of the button clicked', type: ['string', 'null'] },
      country: { description: 'The afected country name', type: ['string', 'null'] },
      page_name: {
        description:
          'The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE',
        type: ['string', 'null'],
      },
      previous_page_name: {
        description:
          'The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE',
        type: ['string', 'null'],
      },
    },
    required: ['country', 'previous_page_name', 'page_name', 'button_name'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Variant Details Edition Discarded',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'VariantDetailsEditionStarted' track call.
 *
 * @param VariantDetailsEditionStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function variantDetailsEditionStarted(
  props: VariantDetailsEditionStarted,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Variant_Details_Edition_Started',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Fired when the user clicks on Edit button (Pencil icon) on the Product type section at the Variant details page',
    properties: {
      button_name: { description: 'The name of the button clicked', type: ['string', 'null'] },
      country: { description: 'The afected country name', type: ['string', 'null'] },
      page_name: {
        description:
          'The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE',
        type: ['string', 'null'],
      },
      previous_page_name: {
        description:
          'The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE',
        type: ['string', 'null'],
      },
      variant_details_attributes: {
        description: 'The attributes and values at the start of the edition process',
        type: ['string', 'null'],
      },
      variant_gtin: {
        description: 'The GTIN is the number used to globally identify the product',
        type: ['integer', 'null'],
      },
      variant_name: {
        description: 'The name of the variant to be created',
        type: ['string', 'null'],
      },
      variant_status: {
        description: 'The status can be enable or disable',
        enum: ['DISABLE', 'ENABLE', null],
        type: ['string', 'null'],
      },
    },
    required: [
      'country',
      'previous_page_name',
      'page_name',
      'variant_name',
      'variant_gtin',
      'variant_status',
      'button_name',
      'variant_details_attributes',
    ],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Variant Details Edition Started',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'VariantDetailsEditionSubmitted' track call.
 *
 * @param VariantDetailsEditionSubmitted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function variantDetailsEditionSubmitted(
  props: VariantDetailsEditionSubmitted,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Variant_Details_Edition_Submitted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Fired when the user clicks on Save changes button on the Product type section at the Variant details page',
    properties: {
      button_name: { description: 'The name of the button clicked', type: ['string', 'null'] },
      country: { description: 'The afected country name', type: ['string', 'null'] },
      page_name: {
        description:
          'The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE',
        type: ['string', 'null'],
      },
      previous_page_name: {
        description:
          'The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE',
        type: ['string', 'null'],
      },
      variant_details_attributes: {
        description: 'The attributes and values at the start of the edition process',
        type: ['string', 'null'],
      },
      variant_gtin: {
        description: 'The GTIN is the number used to globally identify the product',
        type: ['integer', 'null'],
      },
      variant_name: {
        description: 'The name of the variant to be created',
        type: ['string', 'null'],
      },
      variant_status: {
        description: 'The status can be enable or disable',
        enum: ['DISABLE', 'ENABLE', null],
        type: ['string', 'null'],
      },
    },
    required: [
      'country',
      'previous_page_name',
      'page_name',
      'variant_name',
      'variant_gtin',
      'variant_status',
      'button_name',
      'variant_details_attributes',
    ],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Variant Details Edition Submitted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'VariantDetailsPageViewed' track call.
 *
 * @param VariantDetailsPageViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function variantDetailsPageViewed(
  props: VariantDetailsPageViewed,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Variant_Details_Page_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Fired when the user views the Variant Details Page',
    properties: {
      country: { description: 'The afected country name', type: ['string', 'null'] },
      page_name: {
        description:
          'The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE',
        type: ['string', 'null'],
      },
      previous_page_name: {
        description:
          'The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE',
        type: ['string', 'null'],
      },
    },
    required: ['page_name', 'previous_page_name', 'country'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Variant Details Page Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'VariantImageEditionSubmitted' track call.
 *
 * @param VariantImageEditionSubmitted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function variantImageEditionSubmitted(
  props: VariantImageEditionSubmitted,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Variant_Image_Edition_Submitted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Fired when the user changes the Variant Image on the Variant Image section at the Variant details page',
    properties: {
      button_name: { description: 'The name of the button clicked', type: ['string', 'null'] },
      country: { description: 'The afected country name', type: ['string', 'null'] },
      page_name: {
        description:
          'The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE',
        type: ['string', 'null'],
      },
      previous_page_name: {
        description:
          'The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE',
        type: ['string', 'null'],
      },
      variant_gtin: {
        description: 'The GTIN is the number used to globally identify the product',
        type: ['integer', 'null'],
      },
      variant_image: {
        description: 'It must be an optinal reference if the variant is beeing created with image',
        type: ['object', 'null'],
      },
      variant_name: {
        description: 'The name of the variant to be created',
        type: ['string', 'null'],
      },
      variant_status: {
        description: 'The status can be enable or disable',
        enum: ['DISABLE', 'ENABLE', null],
        type: ['string', 'null'],
      },
    },
    required: [
      'country',
      'previous_page_name',
      'page_name',
      'variant_name',
      'variant_image',
      'variant_gtin',
      'variant_status',
      'button_name',
    ],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Variant Image Edition Submitted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'VariantInformationEditionDiscarded' track call.
 *
 * @param VariantInformationEditionDiscarded props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function variantInformationEditionDiscarded(
  props: VariantInformationEditionDiscarded,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Variant_Information_Edition_Discarded',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Fired when the user clicks on CANCEL button anytime during the Variant Information edition',
    properties: {
      button_name: { description: 'The name of the button clicked', type: ['string', 'null'] },
      country: { description: 'The afected country name', type: ['string', 'null'] },
      page_name: {
        description:
          'The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE',
        type: ['string', 'null'],
      },
      previous_page_name: {
        description:
          'The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE',
        type: ['string', 'null'],
      },
    },
    required: ['country', 'previous_page_name', 'page_name', 'button_name'],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Variant Information Edition Discarded',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'VariantInformationEditionStarted' track call.
 *
 * @param VariantInformationEditionStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function variantInformationEditionStarted(
  props: VariantInformationEditionStarted,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Variant_Information_Edition_Started',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Fired when the user clicks on Edit button (Pencil icon) on the Variant Information section at the Variant details page',
    properties: {
      button_name: { description: 'The name of the button clicked', type: ['string', 'null'] },
      country: { description: 'The afected country name', type: ['string', 'null'] },
      page_name: {
        description:
          'The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE',
        type: ['string', 'null'],
      },
      previous_page_name: {
        description:
          'The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE',
        type: ['string', 'null'],
      },
      variant_gtin: {
        description: 'The GTIN is the number used to globally identify the product',
        type: ['integer', 'null'],
      },
      variant_name: {
        description: 'The name of the variant to be created',
        type: ['string', 'null'],
      },
      variant_status: {
        description: 'The status can be enable or disable',
        enum: ['DISABLE', 'ENABLE', null],
        type: ['string', 'null'],
      },
    },
    required: [
      'country',
      'previous_page_name',
      'page_name',
      'variant_name',
      'variant_gtin',
      'variant_status',
      'button_name',
    ],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Variant Information Edition Started',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}
/**
 * Fires a 'VariantInformationEditionSubmitted' track call.
 *
 * @param VariantInformationEditionSubmitted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function variantInformationEditionSubmitted(
  props: VariantInformationEditionSubmitted,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Variant_Information_Edition_Submitted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Fired when the user clicks on Save changes button on the Variant Information section at the Variant details page',
    properties: {
      button_name: { description: 'The name of the button clicked', type: ['string', 'null'] },
      country: { description: 'The afected country name', type: ['string', 'null'] },
      page_name: {
        description:
          'The name of the page. Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE',
        type: ['string', 'null'],
      },
      previous_page_name: {
        description:
          'The previous page name.Permitted Values: PRODUCT_LISTING_PAGE, PRODUCT_DETAILS_PAGE, VARIANT_DETAILS_PAGE, ITEM_DETAILS_PAGE, CATEGORY_TREE_PAGE, BEES_HUB_HOMEPAGE',
        type: ['string', 'null'],
      },
      variant_gtin: {
        description: 'The GTIN is the number used to globally identify the product',
        type: ['integer', 'null'],
      },
      variant_name: {
        description: 'The name of the variant to be created',
        type: ['string', 'null'],
      },
      variant_status: {
        description: 'The status can be enable or disable',
        enum: ['DISABLE', 'ENABLE', null],
        type: ['string', 'null'],
      },
    },
    required: [
      'country',
      'previous_page_name',
      'page_name',
      'variant_name',
      'variant_gtin',
      'variant_status',
      'button_name',
    ],
    type: 'object',
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Variant Information Edition Submitted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    );
  }
}

const clientAPI = {
  /**
   * Updates the run-time configuration of this Typewriter client.
   *
   * @param {TypewriterOptions} options - the options to upsert
   *
   * @typedef {Object} TypewriterOptions
   * @property {AnyAnalytics} [analytics] - Underlying analytics instance where analytics
   * 		calls are forwarded on to. Defaults to window.analytics.
   * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
   * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
   * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
   * 		will be logged to stderr.
   */
  setTypewriterOptions,

  /**
   * Fires a 'AIProductDescriptionCreated' track call.
   *
   * @param AIProductDescriptionCreated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  aIProductDescriptionCreated,
  /**
   * Fires a 'AssignProductsDiscarded' track call.
   *
   * @param AssignProductsDiscarded props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  assignProductsDiscarded,
  /**
   * Fires a 'AssignProductsRemoved' track call.
   *
   * @param AssignProductsRemoved props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  assignProductsRemoved,
  /**
   * Fires a 'AssignProductsStarted' track call.
   *
   * @param AssignProductsStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  assignProductsStarted,
  /**
   * Fires a 'AssignProductsSubmitted' track call.
   *
   * @param AssignProductsSubmitted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  assignProductsSubmitted,
  /**
   * Fires a 'BulkImportDiscarded' track call.
   *
   * @param BulkImportDiscarded props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  bulkImportDiscarded,
  /**
   * Fires a 'BulkImportStarted' track call.
   *
   * @param BulkImportStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  bulkImportStarted,
  /**
   * Fires a 'BulkImportSubmitted' track call.
   *
   * @param BulkImportSubmitted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  bulkImportSubmitted,
  /**
   * Fires a 'BulkImporterPageViewed' track call.
   *
   * @param BulkImporterPageViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  bulkImporterPageViewed,
  /**
   * Fires a 'CategoryAddProductsEditionDiscarded' track call.
   *
   * @param CategoryAddProductsEditionDiscarded props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  categoryAddProductsEditionDiscarded,
  /**
   * Fires a 'CategoryAddProductsEditionStarted' track call.
   *
   * @param CategoryAddProductsEditionStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  categoryAddProductsEditionStarted,
  /**
   * Fires a 'CategoryAddProductsEditionSubmitted' track call.
   *
   * @param CategoryAddProductsEditionSubmitted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  categoryAddProductsEditionSubmitted,
  /**
   * Fires a 'CategoryAddProductsFilterApplied' track call.
   *
   * @param CategoryAddProductsFilterApplied props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  categoryAddProductsFilterApplied,
  /**
   * Fires a 'CategoryAddProductsSearch' track call.
   *
   * @param CategoryAddProductsSearch props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  categoryAddProductsSearch,
  /**
   * Fires a 'CategoryChangeProductPositionDiscarded' track call.
   *
   * @param CategoryChangeProductPositionDiscarded props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  categoryChangeProductPositionDiscarded,
  /**
   * Fires a 'CategoryChangeProductPositionStarted' track call.
   *
   * @param CategoryChangeProductPositionStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  categoryChangeProductPositionStarted,
  /**
   * Fires a 'CategoryChangeProductPositionSubmitted' track call.
   *
   * @param CategoryChangeProductPositionSubmitted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  categoryChangeProductPositionSubmitted,
  /**
   * Fires a 'CategoryCreationDiscarded' track call.
   *
   * @param CategoryCreationDiscarded props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  categoryCreationDiscarded,
  /**
   * Fires a 'CategoryCreationStarted' track call.
   *
   * @param CategoryCreationStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  categoryCreationStarted,
  /**
   * Fires a 'CategoryCreationSubmitted' track call.
   *
   * @param CategoryCreationSubmitted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  categoryCreationSubmitted,
  /**
   * Fires a 'CategoryDetailsEditionDiscarded' track call.
   *
   * @param CategoryDetailsEditionDiscarded props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  categoryDetailsEditionDiscarded,
  /**
   * Fires a 'CategoryDetailsEditionStarted' track call.
   *
   * @param CategoryDetailsEditionStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  categoryDetailsEditionStarted,
  /**
   * Fires a 'CategoryDetailsEditionSubmitted' track call.
   *
   * @param CategoryDetailsEditionSubmitted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  categoryDetailsEditionSubmitted,
  /**
   * Fires a 'CategoryDetailsPageView' track call.
   *
   * @param CategoryDetailsPageView props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  categoryDetailsPageView,
  /**
   * Fires a 'CategoryPositionEditionDiscarded' track call.
   *
   * @param CategoryPositionEditionDiscarded props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  categoryPositionEditionDiscarded,
  /**
   * Fires a 'CategoryPositionEditionStarted' track call.
   *
   * @param CategoryPositionEditionStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  categoryPositionEditionStarted,
  /**
   * Fires a 'CategoryPositionEditionSubmitted' track call.
   *
   * @param CategoryPositionEditionSubmitted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  categoryPositionEditionSubmitted,
  /**
   * Fires a 'CategoryStatusToggleChanged' track call.
   *
   * @param CategoryStatusToggleChanged props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  categoryStatusToggleChanged,
  /**
   * Fires a 'CategoryTreePageViewed' track call.
   *
   * @param CategoryTreePageViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  categoryTreePageViewed,
  /**
   * Fires a 'CategoryUnassignProductDiscarded' track call.
   *
   * @param CategoryUnassignProductDiscarded props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  categoryUnassignProductDiscarded,
  /**
   * Fires a 'CategoryUnassignProductStarted' track call.
   *
   * @param CategoryUnassignProductStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  categoryUnassignProductStarted,
  /**
   * Fires a 'CategoryUnassignProductSubmitted' track call.
   *
   * @param CategoryUnassignProductSubmitted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  categoryUnassignProductSubmitted,
  /**
   * Fires a 'ItemDetailsPageViewed' track call.
   *
   * @param ItemDetailsPageViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  itemDetailsPageViewed,
  /**
   * Fires a 'ItemFilterInteraction' track call.
   *
   * @param ItemFilterInteraction props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  itemFilterInteraction,
  /**
   * Fires a 'ItemInformationEditionDiscarded' track call.
   *
   * @param ItemInformationEditionDiscarded props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  itemInformationEditionDiscarded,
  /**
   * Fires a 'ItemInformationEditionStarted' track call.
   *
   * @param ItemInformationEditionStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  itemInformationEditionStarted,
  /**
   * Fires a 'ItemInformationEditionSubmitted' track call.
   *
   * @param ItemInformationEditionSubmitted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  itemInformationEditionSubmitted,
  /**
   * Fires a 'ItemListingPageViewed' track call.
   *
   * @param ItemListingPageViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  itemListingPageViewed,
  /**
   * Fires a 'ItemSearch' track call.
   *
   * @param ItemSearch props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  itemSearch,
  /**
   * Fires a 'ItemTypeEditionDiscarded' track call.
   *
   * @param ItemTypeEditionDiscarded props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  itemTypeEditionDiscarded,
  /**
   * Fires a 'ItemTypeEditionStarted' track call.
   *
   * @param ItemTypeEditionStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  itemTypeEditionStarted,
  /**
   * Fires a 'ItemTypeEditionSubmitted' track call.
   *
   * @param ItemTypeEditionSubmitted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  itemTypeEditionSubmitted,
  /**
   * Fires a 'ProductCreationDiscarded' track call.
   *
   * @param ProductCreationDiscarded props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  productCreationDiscarded,
  /**
   * Fires a 'ProductCreationStarted' track call.
   *
   * @param ProductCreationStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  productCreationStarted,
  /**
   * Fires a 'ProductCreationSubmitted' track call.
   *
   * @param ProductCreationSubmitted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  productCreationSubmitted,
  /**
   * Fires a 'ProductDetailsPageViewed' track call.
   *
   * @param ProductDetailsPageViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  productDetailsPageViewed,
  /**
   * Fires a 'ProductImageEditionSubmitted' track call.
   *
   * @param ProductImageEditionSubmitted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  productImageEditionSubmitted,
  /**
   * Fires a 'ProductInformationEditionDiscarded' track call.
   *
   * @param ProductInformationEditionDiscarded props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  productInformationEditionDiscarded,
  /**
   * Fires a 'ProductInformationEditionStarted' track call.
   *
   * @param ProductInformationEditionStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  productInformationEditionStarted,
  /**
   * Fires a 'ProductInformationEditionSubmitted' track call.
   *
   * @param ProductInformationEditionSubmitted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  productInformationEditionSubmitted,
  /**
   * Fires a 'ProductListingPageViewed' track call.
   *
   * @param ProductListingPageViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  productListingPageViewed,
  /**
   * Fires a 'ProductTypeValuesEditionDiscarded' track call.
   *
   * @param ProductTypeValuesEditionDiscarded props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  productTypeValuesEditionDiscarded,
  /**
   * Fires a 'ProductTypeValuesEditionStarted' track call.
   *
   * @param ProductTypeValuesEditionStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  productTypeValuesEditionStarted,
  /**
   * Fires a 'ProductTypeValuesEditionSubmitted' track call.
   *
   * @param ProductTypeValuesEditionSubmitted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  productTypeValuesEditionSubmitted,
  /**
   * Fires a 'VariantCreationDiscarded' track call.
   *
   * @param VariantCreationDiscarded props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  variantCreationDiscarded,
  /**
   * Fires a 'VariantCreationStarted' track call.
   *
   * @param VariantCreationStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  variantCreationStarted,
  /**
   * Fires a 'VariantCreationSubmitted' track call.
   *
   * @param VariantCreationSubmitted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  variantCreationSubmitted,
  /**
   * Fires a 'VariantDetailsEditionDiscarded' track call.
   *
   * @param VariantDetailsEditionDiscarded props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  variantDetailsEditionDiscarded,
  /**
   * Fires a 'VariantDetailsEditionStarted' track call.
   *
   * @param VariantDetailsEditionStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  variantDetailsEditionStarted,
  /**
   * Fires a 'VariantDetailsEditionSubmitted' track call.
   *
   * @param VariantDetailsEditionSubmitted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  variantDetailsEditionSubmitted,
  /**
   * Fires a 'VariantDetailsPageViewed' track call.
   *
   * @param VariantDetailsPageViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  variantDetailsPageViewed,
  /**
   * Fires a 'VariantImageEditionSubmitted' track call.
   *
   * @param VariantImageEditionSubmitted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  variantImageEditionSubmitted,
  /**
   * Fires a 'VariantInformationEditionDiscarded' track call.
   *
   * @param VariantInformationEditionDiscarded props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  variantInformationEditionDiscarded,
  /**
   * Fires a 'VariantInformationEditionStarted' track call.
   *
   * @param VariantInformationEditionStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  variantInformationEditionStarted,
  /**
   * Fires a 'VariantInformationEditionSubmitted' track call.
   *
   * @param VariantInformationEditionSubmitted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  variantInformationEditionSubmitted,
};

export default new Proxy<typeof clientAPI>(clientAPI, {
  get(target, method) {
    if (typeof method === 'string' && target.hasOwnProperty(method)) {
      return target[method as keyof typeof clientAPI];
    }

    return () => {
      console.warn(`⚠️  You made an analytics call (${String(method)}) that can't be found. Either:
         a) Re-generate your typewriter client: \`npx typewriter\`
         b) Add it to your Tracking Plan: https://app.segment.com/segment-oscb/protocols/tracking-plans/rs_1zTHJU9fd5mt7cndWnd4PgJbMCE`);
      const a = analytics();
      if (a) {
        a.track(
          'Unknown Analytics Call Fired',
          {
            method,
          },
          withTypewriterContext()
        );
      }
    };
  },
});
