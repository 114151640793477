import { RecentActivityItemProps } from '@components/RecentActivity/components/RecentActivityItem/RecentActivityItem';
import { ActionProps } from '../useRecentActivity.types';
import { MAX_STRING_LENGTH } from '../constants';
import { useIntl } from 'react-intl';

const formatCreateLog = ({
  formatMessage,
  entity,
  fields,
}: ActionProps): RecentActivityItemProps['log'] => {
  const title = formatMessage({ id: 'RecentActivity.ENTITY_CREATE_TITLE' }, { entity });
  const details = [
    formatMessage(
      { id: 'RecentActivity.ENTITY_CREATE_DESCRIPTION' },
      { entity, value: fields[0].current }
    ),
  ];
  return {
    title,
    details,
  };
};

const abbreviateString = (str: string): string => {
  if (str.length <= MAX_STRING_LENGTH) {
    return str;
  }
  return str.slice(0, MAX_STRING_LENGTH) + '...';
};

const formatUpdateInformationLog = ({
  formatMessage,
  entity,
  fields,
  action,
}: ActionProps): RecentActivityItemProps['log'] => {
  const title =
    action === 'UPDATE_ATTRIBUTES'
      ? formatMessage(
          { id: 'RecentActivity.ENTITY_UPDATE_FIELD_TITLE' },
          { entity, field: 'attributes' }
        )
      : fields.length === 1
      ? formatMessage(
          { id: 'RecentActivity.ENTITY_UPDATE_FIELD_TITLE' },
          { entity, field: fields[0].label }
        )
      : formatMessage(
          { id: 'RecentActivity.ENTITY_UPDATE_FIELD_TITLE' },
          { entity, field: undefined }
        );

  const details = fields.map((field) => {
    const shortOldValue = abbreviateString(field.previous.toString());
    const shortNewValue = abbreviateString(field.current.toString());

    return formatMessage(
      { id: 'RecentActivity.ENTITY_UPDATE_FIELD_DESCRIPTION' },
      { entity, field: field.label, previous: shortOldValue, current: shortNewValue }
    );
  });

  return {
    title,
    details,
  };
};

const formatUpdateImageLog = ({
  formatMessage,
  entity,
}: ActionProps): RecentActivityItemProps['log'] => {
  const title = formatMessage(
    { id: 'RecentActivity.ENTITY_UPDATE_FIELD_TITLE' },
    { entity, field: 'image' }
  );
  const details = [
    formatMessage(
      { id: 'RecentActivity.ENTITY_UPDATE_FIELD_DESCRIPTION' },
      { entity, field: 'image' }
    ),
  ];

  return {
    title,
    details,
  };
};

const formatRemoveImageLog = ({
  formatMessage,
  entity,
}: ActionProps): RecentActivityItemProps['log'] => {
  const title = formatMessage({ id: 'RecentActivity.ENTITY_REMOVE_IMAGE_TITLE' }, { entity });
  const details = [
    formatMessage({ id: 'RecentActivity.ENTITY_REMOVE_IMAGE_DESCRIPTION' }, { entity }),
  ];
  return {
    title,
    details,
  };
};

const formatAssignmentLog = ({
  formatMessage,
  action,
  entityName,
  fields,
}: ActionProps): RecentActivityItemProps['log'] => {
  const title = formatMessage({ id: 'RecentActivity.ENTITY_ASSIGNMENT_TITLE' }, { action });

  const details = fields.map(({ label: subEntityName }) =>
    formatMessage(
      { id: 'RecentActivity.ENTITY_ASSIGNMENT_DESCRIPTION' },
      { action, entityName, subEntityName }
    )
  );

  return {
    title,
    details,
  };
};

export const useFormatters = () => {
  const { formatMessage } = useIntl();

  return {
    CREATE: (props) => formatCreateLog({ formatMessage, ...props }),
    UPDATE_INFORMATION: (props) => formatUpdateInformationLog({ formatMessage, ...props }),
    UPDATE_ATTRIBUTES: (props) => formatUpdateInformationLog({ formatMessage, ...props }),
    UPDATE_IMAGE: (props) => formatUpdateImageLog({ formatMessage, ...props }),
    REMOVE_IMAGE: (props) => formatRemoveImageLog({ formatMessage, ...props }),
    ASSIGN_PRODUCT: (props) => formatAssignmentLog({ formatMessage, ...props }),
    UNASSIGN_PRODUCT: (props) => formatAssignmentLog({ formatMessage, ...props }),
    ASSIGN_CATEGORY: (props) => formatAssignmentLog({ formatMessage, ...props }),
    UNASSIGN_CATEGORY: (props) => formatAssignmentLog({ formatMessage, ...props }),
  };
};
