import { createEvent } from 'effector';

export const getSellersDone = createEvent<{
  vendors: Sellers[];
  pagination: SellersPaginationResponse;
}>('getSellersDone');

export const getSellersWithFilterDone = createEvent<{
  vendors: Sellers[];
  pagination: SellersPaginationResponse;
}>('getSellersWithFilterDone');

export const setDefaultSeller = createEvent<Sellers>('setDefaultSeller');
export const setPagesToLoad = createEvent<number[]>('setPagesToLoad');
export const setPagesToLoadWithFilter = createEvent<number[]>('setPagesToLoadWithFilter');
export const setIsLoadingSellers = createEvent<boolean>('isLoadingSellers');
export const setIsLoadingSellersWithFilter = createEvent<boolean>('setIsLoadingSellersWithFilter');
export const setIsLoadingSellersForItemsTable = createEvent<boolean>(
  'setIsLoadingSellersForItemsTable'
);
export const resetAllSellers = createEvent('resetAllSellers');
export const resetAllSellersWithFilter = createEvent('resetAllSellersWithFilter');
export const resetSellersStore = createEvent('resetSellersStore');
export const resetDefaultSeller = createEvent('resetDefaultSeller');

export const getSellersWithItemTypeDone = createEvent<{
  vendors: Sellers[];
  pagination: SellersPaginationResponse;
}>('getSellersWithItemTypeDone');

export const setIsLoadingSellersWithItemType = createEvent<boolean>('isLoadingSellers');
export const resetAllSellersWithItemType = createEvent('resetAllSellersWithItemType');
export const resetSellersStoreWithItemType = createEvent('resetSellersStoreWithItemType');
