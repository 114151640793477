import { Button, Image } from '@hexa-ui/components';
import { History2 } from '@hexa-ui/icons';
import { ActivityHistory, Card, Paragraph } from '@components';
import { ButtonContainer, EmptyContainer } from './RecentActivity.styles';
import { RecentActivityList } from './components/RecentActivityList/RecentActivityList';
import { useRecentActivityData } from '@hooks/useRecentActivityData/useRecentActivityData';
import { TypeToast, useToast } from 'admin-portal-shared-services';
import EmptyImageActivity from '@assets/empty-recent-activity.svg';
import ErrorImageActivity from '@assets/error-recent-activity.svg';
import { useIntl } from 'react-intl';
import { Features } from '@/providers/FeatureToggleProvider/FeatureToggleProvider.types';
import { useFeatureToggleProvider } from '@/providers/FeatureToggleProvider/FeatureToggleProvider';

export interface RecentActivityProps {
  entity: 'PRODUCTS' | 'VARIANTS' | 'ITEMS' | 'CATEGORIES';
  entityId: string;
  country: string;
  auditDataV1?: AuditResponse;
}

export const RecentActivity = ({ entity, entityId, country, auditDataV1 }: RecentActivityProps) => {
  const { useFeatureToggle } = useFeatureToggleProvider();

  const [isAuditLogEnabled, isLoadingAuditLogFeatureToggle] = useFeatureToggle(
    Features.CAD_AUDITLOG_ENABLED
  );

  const { data, isLoading, hasError } = useRecentActivityData({ entity, entityId, country });

  const { formatMessage } = useIntl();

  const toast = useToast();

  if (isLoadingAuditLogFeatureToggle) return null;

  if (!isAuditLogEnabled) return <ActivityHistory audit={auditDataV1} />;

  const isEmpty = !isLoading && data.length === 0;

  const texts = {
    title: formatMessage({ id: 'RecentActivity.TITLE' }),
    showAll: formatMessage({ id: 'RecentActivity.SHOW_ALL' }),
    copyMessageSuccess: formatMessage({ id: 'RecentActivity.COPY_MESSAGE_SUCCESS' }),
    emptyMessage: formatMessage({ id: 'RecentActivity.EMPTY_MESSAGE' }),
    errorMessage: formatMessage({ id: 'RecentActivity.ERROR_MESSAGE' }),
  };

  const onCopySuccess = () => {
    toast.notify({
      message: texts.copyMessageSuccess,
      type: TypeToast.SUCCESS,
    });
  };

  return (
    <Card id="recent-activity" border="medium" elevated="small" title={texts.title}>
      {hasError ? (
        <EmptyContainer data-testid="recent-activity-error">
          <Image src={ErrorImageActivity} alt={texts.errorMessage} />
          <Paragraph text={texts.errorMessage} alignment="center" />
        </EmptyContainer>
      ) : isEmpty ? (
        <EmptyContainer data-testid="recent-activity-empty">
          <Image src={EmptyImageActivity} alt={texts.emptyMessage} />
          <Paragraph text={texts.emptyMessage} alignment="center" />
        </EmptyContainer>
      ) : (
        <>
          <RecentActivityList
            data={data.slice(0, 3)}
            isDetailsExpandable={false}
            onCopySuccess={onCopySuccess}
          />
        </>
      )}
      <ButtonContainer>
        <Button icon={History2} disabled={isEmpty} variant="secondary" leading>
          {texts.showAll}
        </Button>
      </ButtonContainer>
    </Card>
  );
};
